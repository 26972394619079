import React from 'react';
import PropTypes from 'prop-types';
import './BodyStyle.css'; // Import the CSS file

const BodyLargeMedium = ({ text, color }) => {
  return (
    <p className="body-large-medium" style={{ color: color }}>
      {text}
    </p>
  );
};

BodyLargeMedium.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default BodyLargeMedium;