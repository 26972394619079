import {
    DOCTOR_SUCCESS,
    DOCTOR_FAIL,
    SET_MESSAGE,
    DOCTOR_PENDING,
    ADD_DOCTOR_SUCCESS,
    ADD_DOCTOR_FAIL,
    ADD_DOCTOR_PENDING,
    UPDATE_DOCTOR_SUCCESS,
    UPDATE_DOCTOR_FAIL,
    UPDATE_DOCTOR_PENDING,
    DOCTOR_PRACTICELOCATION_SUCCESS,
    DOCTOR_PRACTICELOCATION_FAIL,
    DOCTOR_PRACTICELOCATION_PENDING

} from "../types";
import UserService from "../../services/user.service";
import { toast} from 'react-toastify'; 

export const getDoctors = (body) => async(dispatch) => {
   
    dispatch({
        type: DOCTOR_PENDING,
        payload: { doctors: "isLoading" },
    });
    return await UserService.getDoctors(body).then(
        (data) => {
            data.data.status ?
                dispatch({
                    type: DOCTOR_SUCCESS,
                    payload: { doctors: data.data.result },
                }) :
                dispatch({
                    type: DOCTOR_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: DOCTOR_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};



    export const addDoctors = (body) => async(dispatch) => {
        dispatch({
            type: ADD_DOCTOR_PENDING,
            payload: { addDoctors: "isLoading" },
        });
        return await UserService.addDoctor(body).then(
            (data) => {
                data.data.status ? toast.success("Doctor added Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
                data.data.status ?
                    dispatch({
                        type: ADD_DOCTOR_SUCCESS,
                        payload: { addDoctors : data.data.status },
                    }) :
                    dispatch({
                        type: ADD_DOCTOR_FAIL,
                        payload: { addDoctors : data.data.status },
                    });
                return Promise.resolve();
            },
            (error) => {
                console.log("error......", error);
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
    
                dispatch({
                    type: ADD_DOCTOR_FAIL,
                });
    
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
    
                return Promise.reject();
            }
        ).catch((error) => {
            console.log(error)
        }).finally(()=>{
            dispatch({
              type: ADD_DOCTOR_FAIL
            })
          });
    };
    
    //update doctor
    export const updateDoctors = (body) => async(dispatch) => {
        dispatch({
            type: UPDATE_DOCTOR_PENDING,
            payload: { updateDoctors: "isLoading" },
        });
        return await UserService.updateDoctor(body).then(
            (data) => {
                data.data.status ? toast.success("Doctor updated Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
                data.data.status ?
                    dispatch({
                        type: UPDATE_DOCTOR_SUCCESS,
                        payload: { updateDoctors : data.data.status },
                    }) :
                    dispatch({
                        type: UPDATE_DOCTOR_FAIL,
                        payload: { updateDoctors : data.data.status },
                    });
                return Promise.resolve();
            },
            (error) => {
                console.log("error......", error);
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
    
                dispatch({
                    type: UPDATE_DOCTOR_FAIL,
                });
    
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
    
                return Promise.reject();
            }
        ).catch((error) => {
            console.log(error)
        }).finally(()=>{
            dispatch({
              type: UPDATE_DOCTOR_FAIL
            })
          });
          
    }
    export const getDoctorsByPracticeLocation = (body) => async(dispatch) => {
        console.log('getDoctorsByPracticeLocation action called'); 
    dispatch({
            type: DOCTOR_PRACTICELOCATION_PENDING,
            payload: { loadingDoctors: true },
        });
        return await UserService.getDoctorsByPracticeLocation(body).then(
            (data) => {
                console.log('API Respons>>>>>>>e:', data); 
                data.data.status ?
                    dispatch({
                        type: DOCTOR_PRACTICELOCATION_SUCCESS,
                        payload: { getDoctors: data.data.result, practiceLocationId: body.practiceLocationId },
                    }) :
                    dispatch({
                        type: DOCTOR_PRACTICELOCATION_FAIL,
                        payload: { getDoctors: [], practiceLocationId: body.practiceLocationId }
                    });
                return Promise.resolve();
            },
            (error) => {
                console.log("error......", error);
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
    
                dispatch({
                    type: DOCTOR_PRACTICELOCATION_FAIL,
                });
    
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
    
                return Promise.reject();
            }
        ).catch((error) => {
            console.log(error)
        });
    };
    