import React, { useRef, useState, useLayoutEffect } from 'react';
import "./Calendar.css";
import BodyExtraSmallMedium from '../designSystem/BodyExtraSmallMedium';
import BodySmallMedium from '../designSystem/BodySmallMedium';

const CalendarMonthView = ({ days, planDates, onDayClick, planStatus, planId, myPlan }) => {
    const DRAFT = 1;
    const NEEDSREVISION = 4;
    const HEADOFFICE = "Head Office";
    const permissions = JSON.parse(localStorage.getItem('permissionsList'));
    const containerRefs = useRef([])
    const [extraCounts, setExtraCounts] = useState({});
    useLayoutEffect(() => {
        const counts = {};
        containerRefs.current.forEach((container, index) => {
            if (container) {
                const containerHeight = container.offsetHeight;
                const spanHeight = 20; // Adjust based on your design
                const maxVisibleSpans = Math.floor(containerHeight / spanHeight);

                const day = days[index];
                const events = getEventsForDay(day)?.planDetails || [];
                const extraCount = events.length - maxVisibleSpans;
                counts[index] = Math.max(0, extraCount);
            }
        });
        setExtraCounts(counts);
    }, [days, planDates]);
    // Function to find the plan date that matches the given day
    const getEventsForDay = (day) => {
        return planDates?.find(plan => {
            const planDate = new Date(plan.planDate);
            return (
                day.isCurrentMonth &&
                planDate.getDate() === day.day &&
                planDate.getMonth() + 1 === day.month &&
                planDate.getFullYear() === day.year
            );
        });
    };
    const handleOnDayClick = (day) => {
        const events = getEventsForDay(day);
        const updatedDay = { ...day, employeePlanId: planId };
        onDayClick(updatedDay, events, planStatus, myPlan);
    };
    const isDateNotValid = (day) => {
        const currentDate = new Date();
        const providedDate = new Date(day.year, day.month - 1, day.day);
        return providedDate <= currentDate;
    };
    return (
        <>
            {/* Weekday Headers */}
            <div className="calendar-weekdays">
                {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
                    <div key={index} className="calendar-day-name">
                        <BodySmallMedium text={day} color="#384153" />
                    </div>
                ))}
            </div>
            <div className="calendar-days">
                {days.map((day, index) => {
                    const events = getEventsForDay(day);
                    const disablePointer =
                        ((myPlan && planStatus !== DRAFT && planStatus !== NEEDSREVISION && events === undefined) || !day.isCurrentMonth || (!myPlan && events === undefined) || (myPlan && !permissions.includes('CanAddPlan')) || (!myPlan && !events?.planDetails[0]?.startTime) || (myPlan && planStatus !== DRAFT && planStatus !== NEEDSREVISION && !events?.planDetails[0]?.startTime) || (myPlan && planStatus === DRAFT && isDateNotValid(day)));

                    return (
                        <div
                            key={index}
                            className="calendar-day"
                            style={{ cursor: disablePointer ? 'default' : 'pointer' }}
                            onClick={() => {
                                if (!disablePointer && day.isCurrentMonth) {
                                    handleOnDayClick(day);
                                }
                            }}
                        >
                            <BodyExtraSmallMedium
                                text={day.day.toString()}
                                color={day.isCurrentMonth ? '#384153' : '#A1A2A4'}
                            />
                            <div
                                className="event-container"
                                ref={(el) => (containerRefs.current[index] = el)}
                            >
                                {events?.planDetails?.slice(0, events.planDetails.length - (extraCounts[index] || 0)).map((detail, idx) => (
                                    <span key={idx} className="event-detail">
                                        {detail.doctorName ? detail.doctorName : detail.practiceLocationName ? detail.practiceLocationName : detail.headOffice ? HEADOFFICE : detail.cityName}
                                    </span>
                                ))}
                                {extraCounts[index] > 0 && (
                                    <span className="extra-count">
                                        +{extraCounts[index]} more
                                    </span>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default CalendarMonthView;

