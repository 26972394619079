import React from 'react';
import "./Calendar.css";
import BodySmallMedium from '../designSystem/BodySmallMedium';
import Heading6Semibold from '../designSystem/Heading6Semibold';

const CalendarWeekView = ({ weekDays, isSameDate, formatHour, planDates, onDayClick, planStatus, planId, myPlan }) => {
    const DRAFT = 1;
    const NEEDSREVISION = 4;
    const HEADOFFICE = "Head Office";
    const permissions = JSON.parse(localStorage.getItem('permissionsList'));

    const handleOnDayClick = (day) => {
        const events = getEventsForDay(day);
        const updatedDay = { ...day, employeePlanId: planId };
        onDayClick(updatedDay, events, planStatus, myPlan);
    };
    const getEventsForDay = (day) => {
        return planDates?.find(plan => {
            const planDate = new Date(plan.planDate);
            return (
                day.isCurrentMonth &&
                planDate.getDate() === day.day &&
                planDate.getMonth() + 1 === day.month &&
                planDate.getFullYear() === day.year
            );
        });
    };
    const getEventsForDayAndHour = (day, hour) => {
        const normalizedHour = hour.toString().padStart(2, "0");

        const plan = planDates?.find((plan) => {
            const planDate = new Date(plan.planDate);
            return (
                day.isCurrentMonth &&
                planDate.getDate() === day.day &&
                planDate.getMonth() + 1 === day.month &&
                planDate.getFullYear() === day.year
            );
        });

        return plan?.planDetails?.filter((detail) => {
            if (detail.startTime) {
                const eventHour = detail.startTime.split(":")[0];
                return eventHour === normalizedHour;
            } else {
                return true;
            }
        });
    };
    const isDateNotValid = (day) => {
        const currentDate = new Date();
        const providedDate = new Date(day.year, day.month - 1, day.day);
        return providedDate <= currentDate;
    };
    return (
        <>
            <div className="calendar-week-weekdays">
                {weekDays.map((day, index) => (
                    <div key={index} className="week-calendar-day-name">
                        <Heading6Semibold
                            text={day.day}
                            color={isSameDate(day, new Date()) ? "#006CFA" : day.isCurrentMonth ? "#384153" : "#A1A2A4"}
                        />
                        <BodySmallMedium
                            text={['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][index]}
                            color={isSameDate(day, new Date()) ? "#006CFA" : day.isCurrentMonth ? "#384153" : "#A1A2A4"}
                        />
                    </div>
                ))}
            </div>
            {Array.from({ length: 24 }, (_, hour) => (
                <div key={hour} className="week-hour-row">
                    <BodySmallMedium text={formatHour(hour)} color="#384153" />
                    <div className="calendar-week-days">
                        {weekDays.map((day, index) => {
                            const events = getEventsForDayAndHour(day, hour);
                            const disablePointer =
                                ((myPlan && planStatus !== DRAFT && planStatus !== NEEDSREVISION && events === undefined) || !day.isCurrentMonth || (!myPlan && events === undefined) || (myPlan && !permissions.includes('CanAddPlan')) || (!myPlan && events && !events[0]?.startTime) || (myPlan && events && !events[0]?.startTime) || (myPlan && planStatus === DRAFT && isDateNotValid(day)));
                            return (
                                <div key={index}
                                    className="calendar-day"
                                    style={{ cursor: disablePointer ? 'default' : 'pointer' }}
                                    onClick={() => {
                                        if (!disablePointer && day.isCurrentMonth) {
                                            handleOnDayClick(day, hour);
                                        }
                                    }}
                                >
                                    <div className="event-container">
                                        {events?.map((detail, idx) => (
                                            <span key={idx} className="event-detail">
                                                {detail.doctorName ? detail.doctorName : detail.practiceLocationName ? detail.practiceLocationName : detail.headOffice ? HEADOFFICE : detail.cityName}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ))}
        </>
    );
};

export default CalendarWeekView;
