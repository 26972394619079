import React from 'react';
import './CustomSelect.css';

const CustomSelect = ({ children, value, onChange, disabled = false }) => {
    return (
        <select
            className={`custom-normalselect ${disabled ? 'custom-disabled' : ''}`}
            value={value}
            onChange={onChange}
            disabled={disabled}
        >
            {children}
        </select>
    );
};

export default CustomSelect;

