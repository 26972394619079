import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import "./Calendar.css";
import NavigationHeader from './NavigationHeader';
import CalendarDayView from './CalendarDayView';
import CalendarWeekView from './CalendarWeekView';
import CalendarMonthView from './CalendarMonthView';
import { getEmployeePlan } from '../../actions/userActions/EmployeePlanAction';
import { useDispatch, useSelector } from 'react-redux';
import CalendarListView from './CalendarListView';



// Helper function to get the first day of the month (0 = Sunday, 1 = Monday, etc.)
const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
};

// Helper function to get the number of days in a given month
const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
};
const getCurrentWeek = (currentDate) => {
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
    const weekArray = [];
    for (let i = 0; i < 7; i++) {
        const day = new Date(startOfWeek);
        day.setDate(startOfWeek.getDate() + i);
        weekArray.push({
            day: day.getDate(),
            month: day.getMonth() + 1,
            year: day.getFullYear(),
            isCurrentMonth: day.getMonth() === currentDate.getMonth(),
        });
    }
    return weekArray;
};
const formatHour = (hour) => {
    const suffix = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12; // Convert 0 to 12 for 12 AM/PM
    return `${formattedHour} ${suffix}`;
};

const CustomCalendar = ({ onDayClick }) => {
    const DAYVIEW = "timeGridDay";
    const WEEKVIEW = "timeGridWeek";
    const MONTHVIEW = "dayGridMonth";
    const LISTVIEW = "list";
    const [myPlan, setMyPlan] = useState(true);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [employeePlans, setEmployeePlans] = useState([]);
    const [filteredPlan, setFilteredPlan] = useState();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [days, setDays] = useState([]);
    const calendarRef = useRef(null);
    const [currentMonth, setCurrentMonth] = useState("");
    const [selectedView, setSelectedView] = useState(MONTHVIEW);
    const [weekDays, setWeekDays] = useState([]);
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user?.result[0]?.user.id;
    const dispatch = useDispatch();
    const employeePlan = useSelector((state) => state.employeePlan_reducer.employeePlan);
    const addPlanStatus = useSelector(state => state.employeePlan_reducer.addEmployeePlan);

    const changeView = (viewName) => {
        setSelectedView(viewName);
    };

    const changeWeek = (direction) => {
        setCurrentDate((prevDate) => {
            const newDate = new Date(prevDate);
            newDate.setDate(prevDate.getDate() + direction * 7); // Move forward or backward by 7 days
            return newDate;
        });
    };

    const changeDay = (direction) => {
        setCurrentDate((prevDate) => {
            const newDate = new Date(prevDate);
            newDate.setDate(prevDate.getDate() + direction); // Move forward or backward by 1 day
            return newDate;
        });
    };
    // Handle changing the month (next/previous)
    const changeMonth = (direction) => {
        setCurrentDate((prevDate) => {
            const newDate = new Date(prevDate);
            newDate.setMonth(prevDate.getMonth() + direction); // Move forward or backward by 1 month
            return newDate;
        });
    };

    const changeMonthOrWeek = (direction) => {
        if (selectedView === WEEKVIEW) {
            changeWeek(direction);
        } else if (selectedView === DAYVIEW) {
            changeDay(direction);
        } else {
            changeMonth(direction);
        }
    };
    useEffect(() => {
        if (selectedView === WEEKVIEW) {
            setWeekDays(getCurrentWeek(currentDate));
        } else {
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const firstDay = getFirstDayOfMonth(year, month);
            const daysInMonth = getDaysInMonth(year, month);

            const daysArray = [];
            const prevMonthDays = getDaysInMonth(year, month - 1);

            for (let i = firstDay - 1; i >= 0; i--) {
                daysArray.push({
                    day: prevMonthDays - i,
                    isCurrentMonth: false,
                    month: month,
                    year: year,
                });
            }

            for (let day = 1; day <= daysInMonth; day++) {
                daysArray.push({
                    day,
                    isCurrentMonth: true,
                    month: month,
                    year: year,
                });
            }

            const remainingCells = daysArray.length % 7 === 0 ? 0 : 7 - (daysArray.length % 7);
            for (let day = 1; day <= remainingCells; day++) {
                daysArray.push({
                    day,
                    isCurrentMonth: false,
                    month: month,
                    year: year,
                });
            }

            setDays(daysArray);
        }
    }, [currentDate, selectedView]);


    // Format month for display
    const formatMonth = () => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return `${months[currentDate.getMonth()]} ${currentDate.getFullYear()}`;
    };


    const isSameDate = (date1, date2) => {
        return (
            date1.day === date2.getDate() &&
            date1.month === date2.getMonth() &&
            date1.year === date2.getFullYear()
        );
    };
    const isSameDateDay = (date1, date2) => {
        return (
            date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear()
        );
    };

    useEffect(() => {
        const filteredPlandata = employeePlans?.find(plan => {
            const monthMatches = plan.month === currentDate.getMonth() + 1;
            const yearMatches = plan.year === currentDate.getFullYear();

            if (myPlan) {
                return monthMatches && yearMatches && plan.employeeId == userId;
            } else if (selectedEmployee) {
                return monthMatches && yearMatches && plan.employeeId == selectedEmployee && (plan.statusId === 2 || plan.statusId === 3);
            }
            return false;
        });

        setFilteredPlan(filteredPlandata);
    }, [employeePlans, currentDate, myPlan, selectedEmployee, userId]);

    useEffect(() => {
        if (employeePlan && employeePlan.length !== 0 && employeePlan !== "isLoading" && employeePlan.result && employeePlan.result.length !== 0) {
            setEmployeePlans(prevPlans => {
                const newPlan = employeePlan.result[0];
                const planIndex = prevPlans.findIndex(plan => plan.id === newPlan.id);

                if (planIndex !== -1) {
                    const updatedPlans = [...prevPlans];
                    updatedPlans[planIndex] = newPlan;
                    return updatedPlans;
                } else {
                    return [...prevPlans, newPlan];
                }
            });
        }

    }, [employeePlan]);
    useEffect(() => {
        if (userId && currentDate) {
            const employeeId = userId;
            const month = currentDate.getMonth() + 1;
            const year = currentDate.getFullYear();
            dispatch(getEmployeePlan(employeeId, month, year));
        }
    }, []);
    useEffect(() => {
        if (addPlanStatus && addPlanStatus !== "isLoading" && addPlanStatus === true) {
            if (userId && currentDate) {
                const employeeId = userId;
                const month = currentDate.getMonth() + 1;
                const year = currentDate.getFullYear();
                dispatch(getEmployeePlan(employeeId, month, year));
            }
        }
    }, [addPlanStatus])
    useEffect(() => {
        const newMonth = currentDate.getMonth() + 1;
        if (currentMonth !== newMonth) {
            setCurrentMonth(newMonth);
        }
    }, [currentDate, currentMonth]);
    return (
        <>
            <div className="calender-container">
                <NavigationHeader
                    currentDate={currentDate}
                    selectedView={selectedView}
                    changeView={changeView}
                    changeMonthOrWeek={changeMonthOrWeek}
                    formatMonth={formatMonth}
                    planStatus={filteredPlan ? filteredPlan.statusId : 1}
                    planId={filteredPlan?.id}
                    userId={userId}
                    myPlan={myPlan}
                    setMyPlan={setMyPlan}
                    selectedEmployee={selectedEmployee}
                    setSelectedEmployee={setSelectedEmployee}

                />

                <div className="main-calendar">
                    {selectedView === DAYVIEW ? (
                        <CalendarDayView currentDate={currentDate} isSameDateDay={isSameDateDay} formatHour={formatHour} planDates={filteredPlan?.employeePlanDates} planStatus={filteredPlan?.statusId ? filteredPlan.statusId : 1} onDayClick={onDayClick} planId={filteredPlan?.id} myPlan={myPlan} />
                    ) : selectedView === WEEKVIEW ? (
                        <CalendarWeekView weekDays={weekDays} isSameDate={isSameDate} formatHour={formatHour} planDates={filteredPlan?.employeePlanDates} planStatus={filteredPlan?.statusId ? filteredPlan.statusId : 1} onDayClick={onDayClick} planId={filteredPlan?.id} myPlan={myPlan} />
                    ) : selectedView === MONTHVIEW ? (
                        <CalendarMonthView days={days} planDates={filteredPlan?.employeePlanDates} planStatus={filteredPlan?.statusId ? filteredPlan.statusId : 1} onDayClick={onDayClick} planId={filteredPlan?.id} myPlan={myPlan} />
                    ) : (
                        <CalendarListView planDates={filteredPlan?.employeePlanDates} />
                    )
                    }
                </div>
            </div>
        </>
    );
};

export default CustomCalendar;
