import {
  DESIGNATION_SUCCESS,
  DESIGNATION_FAIL,
  SET_MESSAGE,
  DESIGNATION_PENDING,
  ADD_DESIGNATION_SUCCESS,
  ADD_DESIGNATION_FAIL,
  ADD_DESIGNATION_PENDING,
  UPDATE_DESIGNATION_SUCCESS,
  UPDATE_DESIGNATION_FAIL,
  UPDATE_DESIGNATION_PENDING,
} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";



export const getDesignations = (body) => async (dispatch) => {
  dispatch({
    type: DESIGNATION_PENDING,
    payload: { designations: "isLoading" },
  });
  return await UserService.getDesignations(body)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: DESIGNATION_SUCCESS,
              payload: { designations: data.data },
            })
          : dispatch({
              type: DESIGNATION_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: DESIGNATION_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      return Promise.reject();
    });
};

// Add designation
export const addDesignations = (body) => async (dispatch) => {
  dispatch({
    type: ADD_DESIGNATION_PENDING,
    payload: { addDesignation: "isLoading" },
  });
  return await UserService.addDesignation(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("Designation added Successfully !", {
              autoClose: 2000,
            })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: ADD_DESIGNATION_SUCCESS,
              payload: { addDesignation: data.data.status },
            })
          : dispatch({
              type: ADD_DESIGNATION_FAIL,
              payload: { addDesignation: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: ADD_DESIGNATION_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
    }).finally(()=>{
      dispatch({
        type: ADD_DESIGNATION_FAIL,
      });
    });
};

// Update Designations
export const updateDesignations = (body) => async (dispatch) => {
  dispatch({
    type: UPDATE_DESIGNATION_PENDING,
    payload: { updateDesignation: "isLoading" },
  });
  return await UserService.updateDesignation(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("Designation updated Successfully !", {
              autoClose: 2000,
            })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: UPDATE_DESIGNATION_SUCCESS,
              payload: { updateDesignation: data.data.status },
            })
          : dispatch({
              type: UPDATE_DESIGNATION_FAIL,
              payload: { updateDesignation: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_DESIGNATION_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
    }).finally(()=>{
      dispatch({
        type: UPDATE_DESIGNATION_FAIL,
      });
    });
};
