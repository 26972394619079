import React from 'react';
import PropTypes from 'prop-types';
import './BodyStyle.css'; // Import the CSS file

const Heading6Semibold = ({ text, color }) => {
  return (
    <p className="heading6-semibold" style={{ color: color }}>
      {text}
    </p>
  );
};

Heading6Semibold.propTypes = {
  text: PropTypes.string.isRequired, // Text to display
  color: PropTypes.string.isRequired, // Optional color for the text
};

export default Heading6Semibold;