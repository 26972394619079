import {
    EMPLOYEE_SUCCESS,
    EMPLOYEE_FAIL,
    EMPLOYEE_PENDING,
    SINGLE_EMPLOYEE_SUCCESS,
    SINGLE_EMPLOYEE_FAIL,
    SINGLE_EMPLOYEE_PENDING,
    EMPLOYEE_SUB_ORDINATES_SUCCESS,
    EMPLOYEE_SUB_ORDINATES_FAIL,
    EMPLOYEE_SUB_ORDINATES_PENDING,
    ADD_EMPLOYEE_SUCCESS,
    ADD_EMPLOYEE_FAIL,
    ADD_EMPLOYEE_PENDING,
    UPDATE_EMPLOYEE_SUCCESS,
    UPDATE_EMPLOYEE_FAIL,
    UPDATE_EMPLOYEE_PENDING,
    GENDER_SUCCESS,
    GENDER_PENDING,
    GENDER_FAIL,
    REPORTING_EMPLOYEE_SUCCESS,
    REPORTING_EMPLOYEE_PENDING,
    REPORTING_EMPLOYEE_FAIL,
    EMPLOYEE_BY_LEVEL_FAIL,
    EMPLOYEE_BY_LEVEL_PENDING,
    EMPLOYEE_BY_LEVEL_SUCCESS
} from "../../actions/types";

const initialState = {
    employees: [],
    employee: '',
    addEmployee: '',
    updateEmployee: '',
    employeeSubordinates: [],
    genders: [],
    reportingEmployees: [],
    employeesByLevel: []
};

const employees_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case EMPLOYEE_SUCCESS:
            return {
                ...state,
                employees: payload.employees,
            };
        case EMPLOYEE_PENDING:
            return {
                ...state,
                employees: payload.employees,
            };
        case EMPLOYEE_FAIL:
            return {
                ...state,
                employees: []
            };
        case SINGLE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                employee: payload.employee,
            };
        case SINGLE_EMPLOYEE_PENDING:
            return {
                ...state,
                employee: payload.employee,
            };
        case SINGLE_EMPLOYEE_FAIL:
            return {
                ...state,
                employee: []
            };
        case EMPLOYEE_SUB_ORDINATES_SUCCESS:
            return {
                ...state,
                employeeSubordinates: payload.employeeSubordinates,
            };
        case EMPLOYEE_SUB_ORDINATES_PENDING:
            return {
                ...state,
                employeeSubordinates: payload.employeeSubordinates,
            };
        case EMPLOYEE_SUB_ORDINATES_FAIL:
            return {
                ...state,
                employeeSubordinates: []
            };
        case EMPLOYEE_BY_LEVEL_SUCCESS:
            return {
                ...state,
                employeesByLevel: payload.employeesByLevel,
            };
        case EMPLOYEE_BY_LEVEL_PENDING:
            return {
                ...state,
                employeesByLevel: payload.employeesByLevel,
            };
        case EMPLOYEE_BY_LEVEL_FAIL:
            return {
                ...state,
                employeesByLevel: []
            };
        case REPORTING_EMPLOYEE_SUCCESS:
            return {
                ...state,
                reportingEmployees: payload.reportingEmployees,
            };
        case REPORTING_EMPLOYEE_PENDING:
            return {
                ...state,
                reportingEmployees: payload.reportingEmployees,
            };
        case REPORTING_EMPLOYEE_FAIL:
            return {
                ...state,
                reportingEmployees: []
            };
        case ADD_EMPLOYEE_SUCCESS:
            return {
                ...state,
                addEmployee: payload.addEmployee,
            };
        case ADD_EMPLOYEE_PENDING:
            return {
                ...state,
                addEmployee: payload.addEmployee,
            };
        case ADD_EMPLOYEE_FAIL:
            return {
                ...state,
                addEmployee: false
            };
        case UPDATE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                updateEmployee: payload.updateEmployee,
            };
        case UPDATE_EMPLOYEE_PENDING:
            return {
                ...state,
                updateEmployee: payload.updateEmployee,
            };
        case UPDATE_EMPLOYEE_FAIL:
            return {
                ...state,
                updateEmployee: false
            };
        case GENDER_SUCCESS:
            return {
                ...state,
                genders: payload.genders,
            };
        case GENDER_PENDING:
            return {
                ...state,
                genders: payload.genders,
            };
        case GENDER_FAIL:
            return {
                ...state,
                genders: []
            };
        default:
            return state;
    }
}
export default employees_reducer