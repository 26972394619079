import {
    VISITPROFILE_SUCCESS,
    VISITPROFILE_FAIL,
    SET_MESSAGE,
    VISITPROFILE_PENDING,
    ADD_VISITPROFILE_SUCCESS,
    ADD_VISITPROFILE_FAIL,
    ADD_VISITPROFILE_PENDING,
    UPDATE_VISITPROFILE_SUCCESS,
    UPDATE_VISITPROFILE_FAIL,
    UPDATE_VISITPROFILE_PENDING,
    DELETE_VISITPROFILE_PENDING,
    DELETE_VISITPROFILE_SUCCESS,
    DELETE_VISITPROFILE_FAIL, DELETE_VISITLOCATION_PENDING, DELETE_VISITLOCATION_SUCCESS, DELETE_VISITLOCATION_FAIL,
    VISITPROFILE_DETAIL_FAIL, VISITPROFILE_DETAIL_PENDING, VISITPROFILE_DETAIL_SUCCESS
} from "../types";
import { toast } from 'react-toastify';
import UserService from "../../services/user.service";


export const getvisitProfile = (pageNumber, pageSize, searchQuery) => async (dispatch) => {
    dispatch({
        type: VISITPROFILE_PENDING,
        payload: { visitProfile: "isLoading" },
    });
    return await UserService.getvisitProfile(pageNumber, pageSize, searchQuery).then(
        (data) => {
            console.log(data, "visit data");

            data.data.status ?
                dispatch({
                    type: VISITPROFILE_SUCCESS,
                    payload: { visitProfile: data.data },

                }) :
                dispatch({
                    type: VISITPROFILE_FAIL,
                });
            console.log('visitProfile data', data.data);
            return Promise.resolve();

        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: VISITPROFILE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        return Promise.reject();
    });
};
//get visit profile with detail
export const getVisitProfileDetail = (id) => async (dispatch) => {
    dispatch({
        type: VISITPROFILE_DETAIL_PENDING,
        payload: { visitProfileDetail: "isLoading" },
    });
    return await UserService.getVisitProfileDetail(id).then(
        (data) => {
            console.log(data, "visit profile detail data");

            data.data.status ?
                dispatch({
                    type: VISITPROFILE_DETAIL_SUCCESS,
                    payload: { visitProfileDetail: data.data },

                }) :
                dispatch({
                    type: VISITPROFILE_DETAIL_FAIL,
                });
            return Promise.resolve();

        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: VISITPROFILE_DETAIL_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        return Promise.reject();
    });
};
//add VISIT PROFILE
export const addVisitProfile = (body) => async (dispatch) => {
  dispatch({
    type: ADD_VISITPROFILE_PENDING,
    payload: { addvisitProfile: "isLoading" },
  });

  return await UserService.addVisitProfile(body)
    .then((data) => {
      if (data.data.status) {
        dispatch({
          type: ADD_VISITPROFILE_SUCCESS,
          payload: { addvisitProfile: data.data.status },
        });
      } else {
        toast.error(data.data.message );
        dispatch({
          type: ADD_VISITPROFILE_FAIL,
          payload: { addvisitProfile: data.data.status },
        });
      }
      return Promise.resolve();
    })
    .catch((error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(message);
      dispatch({
        type: ADD_VISITPROFILE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
    .finally(() => {
      dispatch({
        type: ADD_VISITPROFILE_FAIL,
      });
    });
};


export const updateVisitProfile = (body) => async (dispatch) => {
    dispatch({
        type: UPDATE_VISITPROFILE_PENDING,
        payload: { updatevisitProfile: "isLoading" },
    });
    return await UserService.updateVisitProfile(body).then(
        (data) => {
            data.data.status ? toast.success("visit profile updated Successfully !", { autoClose: 2000 }) : toast.error(data.data.message, { autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: UPDATE_VISITPROFILE_SUCCESS,
                    payload: { updatevisitProfile: data.data.status },
                }) :
                dispatch({
                    type: UPDATE_VISITPROFILE_FAIL,
                    payload: { updatevisitProfile: data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: UPDATE_VISITPROFILE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    }).finally(() => {
        dispatch({
            type: UPDATE_VISITPROFILE_FAIL,
        });
    });
};

export const deleteVisitProfile = (visitProfileId) => async (dispatch) => {
    dispatch({
        type: DELETE_VISITLOCATION_PENDING,
        payload: { deleteVisitProfile: "isLoading" },
    });

    try {
        const response = await UserService.deleteVisitProfile(visitProfileId);

        if (response.data.status) {
            dispatch({
                type: DELETE_VISITPROFILE_PENDING,
                payload: { visitProfileId: visitProfileId },
            });
            toast.success("visit profile added Successfully !", { autoClose: 2000 });
        } else {
            dispatch({
                type: DELETE_VISITPROFILE_SUCCESS,
            });

        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();

        dispatch({
            type: DELETE_VISITPROFILE_FAIL,
        });

        dispatch({
            type: SET_MESSAGE,
            payload: message,
        });

        return Promise.reject();
    }
};

export const deleteVisitLocation = (visitLocationId) => async (dispatch) => {
    dispatch({
        type: DELETE_VISITLOCATION_PENDING,
        payload: { deleteVisitLocation: "isLoading" },
    });

    try {
        const response = await UserService.deleteVisitLocation(visitLocationId);

        if (response.data.status) {
            dispatch({
                type: DELETE_VISITLOCATION_SUCCESS,
                payload: { visitLocationId: visitLocationId },
            });
            toast.success(response.data.message, { autoClose: 2000 });
        } else {
            dispatch({
                type: DELETE_VISITLOCATION_FAIL,
            });

        }
    } catch (error) {
        console.error("Error deleting visit location:", error);
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();

        dispatch({
            type: DELETE_VISITLOCATION_FAIL,
        });

        dispatch({
            type: SET_MESSAGE,
            payload: message,
        });

        return Promise.reject();
    }
};

