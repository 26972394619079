import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getDesignations } from '../../actions/userActions/designationAction'
import Loader from '../subComponents/Loader'
import Pagination from '../paginatioin/pagination'
import AddButton from '../subComponents/buttons/AddButton'
import { IconButton,Tooltip } from "@mui/material";
import ServerSideSearch from '../subComponents/search/ServerSideSearch'
import EditButton from '../subComponents/buttons/EditButton'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import BodyExtraSmallMedium from '../designSystem/BodyExtraSmallMedium'
import DynamicTable from '../reuse_components/DynamicTable'
import BodySmallMedium from '../designSystem/BodySmallMedium'
import Heading6Regular from '../designSystem/Heading6Regular'
import SearchInput from '../subComponents/search/SearchInput'

import thIcon from "../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import CustomButton from '../subComponents/buttons/CustomButton'
import CustomPagination from '../subComponents/pagination/CustomPagination'
function Designations() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  
  const designations = useSelector(
    (state) => state.designations_reducer.designations
  )
  const totalPages = designations?.totalPages || 0;
  const totalItems = designations?.totalCount || 0;

  const itemsPerPage = 10;
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  const [selected, setSelected] = useState({
    textSearch: '',
  })
  const [filters, setFilters] = useState({
    textSearch: '',
  })
 

  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  const [query, setQuery] = useState('')
  useEffect(() => {
    const body = {
      filter: filters,
      pageNumber: currentPage,
      pageSize: itemsPerPage,
    };
    dispatch(getDesignations(body));
  }, [currentPage, filters, dispatch]);
  
  const designationData = designations && designations?.result?.[0]?.designations;

  const getPaginationNo = (pageNum) => {
    const object = []
    if (designations !== 'isLoading' && designations.length !== 0) {
      for (let i = 0; i < designations[0].page.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? 'active' : ''}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>,
        )
      }
    }
    return object
  }

  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 1) {
      setTimeout(() => {
        setFilters({ ...filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...filters, ['textSearch']: e.target.value })
    }
  }
  const handleAddDesignationClick = () => {
    history.push('/admin/AddDesignations');
  };
  const DesignationData = designationData?.map((designation) => ({
    ...designation,
    status: designation.active ? "Active" : "In-active",
  }));
  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Description", accessor: "description" },
    { label: "Designation Level", accessor: "levelName" },
    { label: "Status", accessor: "status" },  
  ];
  const handleEditDesignation = (row) => {
    history.push({
      pathname: '/admin/UpdateDesignations',
      designation: { row },
      id: row.id,
    });
  }; const actions = [  
    {
        label: 'Edit designation',
        icon: <EditOutlinedIcon />,
        permission: 'CanUpdateDesignation',
        handler:handleEditDesignation,
    },
];

  return (
    <>
      <div>
      <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Designation" color="#1E293B" />
                </div>
            </div>
            <Heading6Regular text="Designation" color="#1E293B" />
            <div className="content-wrapper">
                <div className="pagination">
                    <SearchInput
                        placeholder="Search"
                        onChange={handleSearch}
              value={selected.textSearch}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {designations !== 'isLoading' && designations.length !== 0 &&(
                        <CustomPagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)} 
                      />
                      )
                      }
                   
                        {permissions && permissions.includes("CanAddDesignation") && (
                            <CustomButton text="Add Designation" width="180px" onClick={handleAddDesignationClick} />
                        )}
                    </div>
                </div>
                <DynamicTable
                    data={ DesignationData}
                    isLoading={designations === "isLoading"}
                    columns={columns}
                    permissions={permissions}
                    actions={actions}
                    thIcon={thIcon}
                />
            </div>

        </div>
        {/* <div className="row">
          <div className="col-12">
            <div className="task-pagination-wrapper">
              {getPaginationNo(page)}
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default Designations
