import React, { useEffect, useState } from "react";
import PlanAccordianView from "./PlanAccordianView";
import PlannerTableView from "./PlannerTableView";

const CalendarListView = ({ planDates }) => {
    const [accordianView, setAccordianView] = useState(false);
    const [accordianDoctor, setAccordianDoctor] = useState(false);

    useEffect(() => {
        if (planDates && planDates.length > 0) {
            const firstPlan = planDates[0];
            if (firstPlan.planDetails && firstPlan.planDetails.length > 0) {
                const firstPlanDetail = firstPlan.planDetails[0];
                if (firstPlanDetail.startTime) {
                    setAccordianView(true);
                    if (firstPlanDetail.doctorName) {
                        setAccordianDoctor(true);
                    }
                }
            }
        }
    }, [planDates]);
    return (
        <>
            {accordianView ? (
                <PlanAccordianView accordianDoctor={accordianDoctor} planDates={planDates} />
            ) : (
                <PlannerTableView planDates={planDates} />
            )
            }
        </>

    )
};
export default CalendarListView;