import React from 'react';
import './Modal.css';
import approvePlanIcon from "../../assets/images/approvePlanIcon.svg";
import Heading6Semibold from '../designSystem/Heading6Semibold';
import BodyMediumRegular from '../designSystem/BodyMediumRegular';
import BodyExtraSmallMedium from '../designSystem/BodyExtraSmallMedium';

const ApprovePlanModal = ({ isVisible, onClose, onApproveClick, isLoading }) => {

    return (
        isVisible && (
            <div className="modal-backdrop">
                <div className="modal-container" onClick={(e) => e.stopPropagation()}>
                    <div className='content-container'>
                        <div className='icon-container'>
                            <img src={approvePlanIcon} />
                        </div>
                        <div className='text-container'>
                            <Heading6Semibold color='#1E293B' text='Approve Plan' />
                            <BodyMediumRegular
                                color='#384153'
                                text='Are you sure you want to approve this monthly plan? Your decision will finalize the review for this employee.'
                                textAlign='center'
                            />
                        </div>
                    </div>
                    <div className='button-contiainer'>
                        <button className='cancel-button'
                            onClick={onClose}
                        >
                            <BodyExtraSmallMedium color='#1E293B' text='Cancel' />
                        </button>
                        <button className='primary-button' onClick={onApproveClick} disabled={isLoading}>
                            <BodyExtraSmallMedium color='#FFFFFF' text='Approve' />
                        </button>

                    </div>
                </div>
            </div>
        )
    );
};

export default ApprovePlanModal;
