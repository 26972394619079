import React from 'react';
import './EventCard.css';
import BodySmallRegular from '../designSystem/BodySmallRegular';
import clockIcon from "../../assets/images/clockIcon.svg";
import practiceLocationIcon from "../../assets/images/Icon=Practice Locations.svg";
import doctorIcon from "../../assets/images/Icon=Doctors.svg";
import morningIcon from "../../assets/images/morningIcon.svg";
import eveningIcon from "../../assets/images/eveningIcon.svg";
import cityIcon from "../../assets/images/Icon=City.svg";

const EventCard = ({ event }) => {
    const formatTime = (time) => {
        const [hour, minute] = time.split(":");
        const hourInt = parseInt(hour, 10);
        const isPM = hourInt >= 12;
        const formattedHour = hourInt % 12 || 12;
        return `${formattedHour}:${minute} ${isPM ? 'PM' : 'AM'}`;
    };

    const startTimeFormatted = formatTime(event?.startTime);
    const endTimeFormatted = formatTime(event?.endTime);

    return (
        <div className="event-card">
            <div className="eventcard-content-container">
                <img src={clockIcon} />
                <BodySmallRegular
                    text={`${startTimeFormatted} - ${endTimeFormatted}`}
                    color='#384153' />
                {event?.shiftId === 1 && (
                    <img src={morningIcon} />
                )}
                {event?.shiftId === 2 && (
                    <img src={eveningIcon} />
                )}
            </div>
            {event && event.cityName && (
                <div className='eventcard-content-container'>
                    <img src={cityIcon} />
                    <BodySmallRegular text={event?.cityName} color='#384153' />
                </div>
            )}
            <div className='eventcard-content-container'>
                <img src={practiceLocationIcon} />
                <BodySmallRegular text={event?.practiceLocationName} color='#384153' />
            </div>
            {event && event.doctorName && (
                <div className='eventcard-content-container'>
                    <img src={doctorIcon} />
                    <BodySmallRegular text={event?.doctorName} color='#384153' />
                </div>
            )}

            <hr></hr>
        </div>
    );
};

export default EventCard;