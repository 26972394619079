import React from 'react';
import './Modal.css';
import eraserIcon from "../../assets/images/eraserIcon.svg";
import Heading6Semibold from '../designSystem/Heading6Semibold';
import BodyMediumRegular from '../designSystem/BodyMediumRegular';
import BodyExtraSmallMedium from '../designSystem/BodyExtraSmallMedium';

const ClearPlanModal = ({ isVisible, onClose }) => {
    return (
        isVisible && (
            <div className="modal-backdrop">
                <div className="modal-container" onClick={(e) => e.stopPropagation()}>
                    <div className='content-container'>
                        <div className='icon-container'>
                            <img src={eraserIcon} />
                        </div>
                        <div className='text-container'>
                            <Heading6Semibold color='#1E293B' text='Clear Plan' />
                            <BodyMediumRegular
                                color='#384153'
                                text='This action will clear all the visits, are you sure you want to continue?'
                                textAlign='center'
                            />
                        </div>
                    </div>
                    <div className='button-contiainer'>
                        <button className='cancel-button'
                            onClick={onClose}
                        >
                            <BodyExtraSmallMedium color='#1E293B' text='Cancel' />
                        </button>
                        <button className='primary-button'>
                            <BodyExtraSmallMedium color='#FFFFFF' text='Clear' />
                        </button>

                    </div>
                </div>
            </div>
        )
    );
};

export default ClearPlanModal;
