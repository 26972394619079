import React, { Children } from 'react';
import './CustomTimeSelect.css';

const CustomTimeSelect = ({ options, value, onChange,placeholder, children }) => {
    return (
        <div className="custom-timeselect-container">
            <select
                className="custom-timeselect"
                value={value}
                onChange={onChange}
            >
                {children}
            </select>
            {/* <input type='time' /> */}
        </div>
    );
};

export default CustomTimeSelect;
