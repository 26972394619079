import React from 'react';
import "./Calendar.css"
import BodySmallMedium from '../designSystem/BodySmallMedium';
import Heading6Semibold from '../designSystem/Heading6Semibold';

const CalendarDayView = ({ currentDate, formatHour, isSameDateDay, planDates, onDayClick, planStatus, planId, myPlan }) => {
    const DRAFT = 1;
    const NEEDSREVISION = 4;
    const HEADOFFICE = "Head Office";
    const permissions = JSON.parse(localStorage.getItem('permissionsList'));
    const day = {
        day: currentDate.getDate(),
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
    };

    const getEventsForDay = (day) => {
        return planDates?.find(plan => {
            const planDate = new Date(plan.planDate);
            return (
                planDate.getDate() === day.day &&
                planDate.getMonth() + 1 === day.month &&
                planDate.getFullYear() === day.year
            );
        });
    };
    const getEventsForDayAndHour = (hour) => {
        const normalizedHour = hour.toString().padStart(2, "0");

        const plan = planDates?.find((plan) => {
            const planDate = new Date(plan.planDate);
            return (
                planDate.getDate() === day.day &&
                planDate.getMonth() + 1 === day.month &&
                planDate.getFullYear() === day.year
            );
        });
        return plan?.planDetails?.filter((detail) => {
            if (detail.startTime) {
                const eventHour = detail.startTime.split(":")[0];
                return eventHour === normalizedHour;
            } else {
                return true;
            }
        });
    };
    const handleOnDayClick = (day) => {
        const events = getEventsForDay(day);
        const updatedDay = { ...day, employeePlanId: planId };
        onDayClick(updatedDay, events, planStatus, myPlan);
    };
    const isDateNotValid = (currentDate) => {
        const currenttodayDate = new Date();
        const providedDate = currentDate;
        return providedDate <= currenttodayDate;
    };
    return (
        <div className="day-view">
            <div className="day-header">
                <Heading6Semibold
                    text={`${day.day}`}
                    color={isSameDateDay(currentDate, new Date()) ? "#006CFA" : "#384153"}
                />
                <BodySmallMedium
                    text={`${['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][currentDate.getDay()]}`}
                    color={isSameDateDay(currentDate, new Date()) ? "#006CFA" : "#384153"}
                />
            </div>

            {Array.from({ length: 24 }, (_, hour) => {
                const events = getEventsForDayAndHour(hour);
                const disablePointer =
                    ((myPlan && planStatus !== DRAFT && planStatus !== NEEDSREVISION && (events?.length === 0 || events === undefined)) ||
                        (!myPlan && (events?.length === 0 || events === undefined)) ||
                        (myPlan && !permissions.includes('CanAddPlan')) || (!myPlan && events && !events[0]?.startTime) || (myPlan && events && !events[0]?.startTime) || (myPlan && planStatus === DRAFT && isDateNotValid(currentDate)));
                return (
                    <div key={hour} className="day-hour-row">
                        <BodySmallMedium text={formatHour(hour)} color="#384153" />
                        <div
                            className="day-hour"
                            style={{ cursor: disablePointer ? 'default' : 'pointer' }}
                            onClick={() => {
                                if (!disablePointer) {
                                    handleOnDayClick(day);
                                }
                            }}
                        >
                            <div className="event-container">
                                {events?.map((detail, idx) => (
                                    <span key={idx} className="event-detail">
                                        {detail.doctorName ? detail.doctorName : detail.practiceLocationName ? detail.practiceLocationName : detail.headOffice ? HEADOFFICE : detail.cityName}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default CalendarDayView;