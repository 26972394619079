import {
    EMPLOYEE_PLAN_SUCCESS,
    EMPLOYEE_PLAN_PENDING,
    SET_MESSAGE,
    EMPLOYEE_PLAN_FAIL,
    ADD_EMPLOYEE_PLAN_FAIL,
    ADD_EMPLOYEE_PLAN_SUCCESS,
    ADD_EMPLOYEE_PLAN_PENDING,
    RESET_ADD_EMPLOYEE_PLAN,
    UPDATE_PLAN_STATUS_FAIL,
    UPDATE_PLAN_STATUS_PENDING,
    UPDATE_PLAN_STATUS_SUCCESS,
    UPDATE_EMPLOYEE_PLAN_FAIL,
    UPDATE_EMPLOYEE_PLAN_SUCCESS,
    UPDATE_EMPLOYEE_PLAN_PENDING,
    APPROVE_EMPLOYEE_PLAN_FAIL,
    APPROVE_EMPLOYEE_PLAN_SUCCESS,
    APPROVE_EMPLOYEE_PLAN_PENDING,
    REJECT_EMPLOYEE_PLAN_FAIL,
    REJECT_EMPLOYEE_PLAN_SUCCESS,
    REJECT_EMPLOYEE_PLAN_PENDING,
} from "../../actions/types";

const initialState = {
    employeePlan: [],
    updatePlanStatus: '',
    addEmployeePlan: '',
    updateEmployeePlan: '',
    rejectEmployeePlan: '',
    approveEmployeePlan: ''
};

const employeePlan_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case EMPLOYEE_PLAN_SUCCESS:
            return {
                ...state,
                employeePlan: payload.employeePlan,
            };
        case EMPLOYEE_PLAN_PENDING:
            return {
                ...state,
                employeePlan: payload.employeePlan,
            };
        case EMPLOYEE_PLAN_FAIL:
            return {
                ...state,
                employeePlan: []
            };
        case ADD_EMPLOYEE_PLAN_SUCCESS:
            return {
                ...state,
                addEmployeePlan: true,
            };
        case ADD_EMPLOYEE_PLAN_PENDING:
            return {
                ...state,
                addEmployeePlan: payload.addEmployeePlan,
            };
        case ADD_EMPLOYEE_PLAN_FAIL:
            return {
                ...state,
                addEmployeePlan: false
            };

        case UPDATE_EMPLOYEE_PLAN_SUCCESS:
            return {
                ...state,
                updateEmployeePlan: true,
            };
        case UPDATE_EMPLOYEE_PLAN_PENDING:
            return {
                ...state,
                updateEmployeePlan: payload.updateEmployeePlan,
            };
        case UPDATE_EMPLOYEE_PLAN_FAIL:
            return {
                ...state,
                updateEmployeePlan: false
            };
        case UPDATE_PLAN_STATUS_SUCCESS:
            return {
                ...state,
                updatePlanStatus: true,
            };
        case UPDATE_PLAN_STATUS_PENDING:
            return {
                ...state,
                updatePlanStatus: payload.updatePlanStatus,
            };
        case UPDATE_PLAN_STATUS_FAIL:
            return {
                ...state,
                updatePlanStatus: false
            };
        case APPROVE_EMPLOYEE_PLAN_SUCCESS:
            return {
                ...state,
                approveEmployeePlan: true,
            };
        case APPROVE_EMPLOYEE_PLAN_PENDING:
            return {
                ...state,
                approveEmployeePlan: payload.approveEmployeePlan,
            };
        case APPROVE_EMPLOYEE_PLAN_FAIL:
            return {
                ...state,
                approveEmployeePlan: false
            };
        case REJECT_EMPLOYEE_PLAN_SUCCESS:
            return {
                ...state,
                rejectEmployeePlan: true,
            };
        case REJECT_EMPLOYEE_PLAN_PENDING:
            return {
                ...state,
                rejectEmployeePlan: payload.rejectEmployeePlan,
            };
        case REJECT_EMPLOYEE_PLAN_FAIL:
            return {
                ...state,
                rejectEmployeePlan: false
            };
        case RESET_ADD_EMPLOYEE_PLAN:
            return {
                ...state,
                addEmployeePlan: ""
            };

        default:
            return state;
    }
}
export default employeePlan_reducer