import {
    EMPLOYEE_PLAN_SUCCESS,
    EMPLOYEE_PLAN_PENDING,
    SET_MESSAGE,
    EMPLOYEE_PLAN_FAIL,
    ADD_EMPLOYEE_PLAN_FAIL,
    ADD_EMPLOYEE_PLAN_SUCCESS,
    ADD_EMPLOYEE_PLAN_PENDING,
    RESET_ADD_EMPLOYEE_PLAN,
    UPDATE_PLAN_STATUS_FAIL,
    UPDATE_PLAN_STATUS_PENDING,
    UPDATE_PLAN_STATUS_SUCCESS,
    UPDATE_EMPLOYEE_PLAN_FAIL,
    UPDATE_EMPLOYEE_PLAN_SUCCESS,
    UPDATE_EMPLOYEE_PLAN_PENDING,
    APPROVE_EMPLOYEE_PLAN_FAIL,
    APPROVE_EMPLOYEE_PLAN_SUCCESS,
    APPROVE_EMPLOYEE_PLAN_PENDING,
    REJECT_EMPLOYEE_PLAN_FAIL,
    REJECT_EMPLOYEE_PLAN_SUCCESS,
    REJECT_EMPLOYEE_PLAN_PENDING,
} from "../types";
import { toast } from 'react-toastify';
import UserService from "../../services/user.service";


export const getEmployeePlan = (employeeId, month, year) => async (dispatch) => {
    dispatch({
        type: EMPLOYEE_PLAN_PENDING,
        payload: { employeePlan: "isLoading" },
    });
    return await UserService.getEmployeePlan(employeeId, month, year).then(
        (data) => {
            data.data.status ?
                dispatch({
                    type: EMPLOYEE_PLAN_SUCCESS,
                    payload: { employeePlan: data.data },

                }) :
                dispatch({
                    type: EMPLOYEE_PLAN_FAIL,
                });
            return Promise.resolve();

        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: EMPLOYEE_PLAN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    ).catch((error) => {
        return Promise.reject();
    });
};
//add employeePlan
export const addEmployeePlan = (body) => async (dispatch) => {
    dispatch({
        type: ADD_EMPLOYEE_PLAN_PENDING,
        payload: { addEmployeePlan: "isLoading" },
    });
    return await UserService.addPlan(body).then(
        (data) => {
            data.data.status ? toast.success(data.data.message, { autoClose: 2000 }) : toast.error(data.data.message, { autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: ADD_EMPLOYEE_PLAN_SUCCESS,
                    payload: { addEmployeePlan: data.data.status },
                }) :
                dispatch({
                    type: ADD_EMPLOYEE_PLAN_FAIL,
                    payload: { addEmployeePlan: data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: ADD_EMPLOYEE_PLAN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        dispatch({
            type: ADD_EMPLOYEE_PLAN_FAIL,
        });
    }).finally(() => {
        dispatch({
            type: ADD_EMPLOYEE_PLAN_FAIL,
        });
    });
};

//update employeePlan
export const updateEmployeePlan = (body) => async (dispatch) => {
    dispatch({
        type: UPDATE_EMPLOYEE_PLAN_PENDING,
        payload: { updateEmployeePlan: "isLoading" },
    });
    return await UserService.updatePlan(body).then(
        (data) => {
            data.data.status ? toast.success(data.data.message, { autoClose: 2000 }) : toast.error(data.data.message, { autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: UPDATE_EMPLOYEE_PLAN_SUCCESS,
                    payload: { updateEmployeePlan: data.data.status },
                }) :
                dispatch({
                    type: UPDATE_EMPLOYEE_PLAN_FAIL,
                    payload: { updateEmployeePlan: data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: UPDATE_EMPLOYEE_PLAN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        dispatch({
            type: UPDATE_EMPLOYEE_PLAN_FAIL,
        });
    }).finally(() => {
        dispatch({
            type: UPDATE_EMPLOYEE_PLAN_FAIL,
        });
    });
};

export const updatePlanStatus = (planId) => async (dispatch) => {
    dispatch({
        type: UPDATE_PLAN_STATUS_PENDING,
        payload: { updatePlanStatus: "isLoading" },
    });
    return await UserService.updatePlanStatus(planId).then(
        (data) => {
            data.data.status ? toast.success(data.data.message, { autoClose: 2000 }) : toast.error(data.data.message, { autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: UPDATE_PLAN_STATUS_SUCCESS,
                    payload: { updatePlanStatus: data.data.status },
                }) :
                dispatch({
                    type: UPDATE_PLAN_STATUS_FAIL,
                    payload: { updatePlanStatus: data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: UPDATE_PLAN_STATUS_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        dispatch({
            type: ADD_EMPLOYEE_PLAN_FAIL,
        });
    }).finally(() => {
        dispatch({
            type: ADD_EMPLOYEE_PLAN_FAIL,
        });
    });
};

export const resetAddEmployeePlan = () => {
    dispatch({
        type: RESET_ADD_EMPLOYEE_PLAN
    });
}

// approve plan
export const approveEmployeePlan = (planId, statusId, approvedBy) => async (dispatch) => {
    dispatch({
        type: APPROVE_EMPLOYEE_PLAN_PENDING,
        payload: { approveEmployeePlan: "isLoading" },
    });
    return await UserService.approvePlan(planId, statusId, approvedBy).then(
        (data) => {
            data.data.status ? toast.success(data.data.message, { autoClose: 2000 }) : toast.error(data.data.message, { autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: APPROVE_EMPLOYEE_PLAN_SUCCESS,
                    payload: { approveEmployeePlan: data.data.status },
                }) :
                dispatch({
                    type: APPROVE_EMPLOYEE_PLAN_FAIL,
                    payload: { approveEmployeePlan: data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: APPROVE_EMPLOYEE_PLAN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        dispatch({
            type: APPROVE_EMPLOYEE_PLAN_FAIL,
        });
    }).finally(() => {
        dispatch({
            type: APPROVE_EMPLOYEE_PLAN_FAIL,
        });
    });
};

// reject plan
export const rejectEmployeePlan = (planId, statusId, rejectedBy) => async (dispatch) => {
    dispatch({
        type: REJECT_EMPLOYEE_PLAN_PENDING,
        payload: { rejectEmployeePlan: "isLoading" },
    });
    return await UserService.rejectPlan(planId, statusId, rejectedBy).then(
        (data) => {
            data.data.status ? toast.success(data.data.message, { autoClose: 2000 }) : toast.error(data.data.message, { autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: REJECT_EMPLOYEE_PLAN_SUCCESS,
                    payload: { rejectEmployeePlan: data.data.status },
                }) :
                dispatch({
                    type: REJECT_EMPLOYEE_PLAN_FAIL,
                    payload: { rejectEmployeePlan: data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: REJECT_EMPLOYEE_PLAN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        dispatch({
            type: REJECT_EMPLOYEE_PLAN_FAIL,
        });
    }).finally(() => {
        dispatch({
            type: REJECT_EMPLOYEE_PLAN_FAIL,
        });
    });
};