import React, { useState } from "react";
import BodySmallMedium from "../designSystem/BodySmallMedium";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import "./Planner.css"
import Heading6Regular from "../designSystem/Heading6Regular";
import CustomCalendar from "./Calendar.jsx";
import ViewDrawer from "./ViewDrawer";
import AddDrawer from "./AddDrawer";
import CopyModal from "./CopyPlanModal";
import CreateTaskModal from "./CreateTaskModal";
import ClearPlanModal from "./ClearPlanModal";


const Planner = () => {
    const DRAFT = 1;
    const NEEDSREVISION = 4;
    const [isViewDrawerOpen, setIsViewDrawerOpen] = useState(false);
    const [isAddDrawerOpen, setIsAddDrawerOpen] = useState(false);
    const [isCopyModalVisible, setIsCopyModalVisisble] = useState(false);
    const [isCreateTaskModalVisible, setIsCreateTaskModalVisisble] = useState(false);
    const [isClearPlanModalVisible, setIsClearPlanModalVisisble] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);
    const [eventsForSelectedDay, setEventsForSelectedDay] = useState(null);

    const onViewDrawerClose = () => {
        setIsViewDrawerOpen(false);
    };
    const onAddDrawerClose = () => {
        setIsAddDrawerOpen(false);
    };
    const onCopyModalClose = () => {
        setIsCopyModalVisisble(false);
    };
    const onCreateTaskModalClose = () => {
        setIsCreateTaskModalVisisble(false);
    };
    const onClearPlanModalClose = () => {
        setIsClearPlanModalVisisble(false);
    };

    const handleDayClick = (day, events, planStatus) => {
        setSelectedDay(day);
        setEventsForSelectedDay(events);
        if (planStatus === DRAFT || planStatus === NEEDSREVISION) {
            setIsAddDrawerOpen(true);
        } else if (planStatus !== DRAFT && planStatus !== NEEDSREVISION && events !== undefined) {
            setIsViewDrawerOpen(true);
        }
    };
    return (
        <div className="planner-container">
            <div className="planner-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Task" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Planner" color="#1E293B" />
                </div>
                <Heading6Regular text="Planner" color="#1E293B" />
            </div>
            <div className="">
                <CustomCalendar onDayClick={handleDayClick} />
            </div>
            <ViewDrawer isOpen={isViewDrawerOpen} day={selectedDay} events={eventsForSelectedDay} onClose={onViewDrawerClose} />
            <AddDrawer isOpen={isAddDrawerOpen} day={selectedDay} events={eventsForSelectedDay} onClose={onAddDrawerClose} />
            <CopyModal isVisible={isCopyModalVisible} onClose={onCopyModalClose} />
            <CreateTaskModal isVisible={isCreateTaskModalVisible} onClose={onCreateTaskModalClose} />
            <ClearPlanModal isVisible={isClearPlanModalVisible} onClose={onClearPlanModalClose} />
        </div>
    )
}

export default Planner;