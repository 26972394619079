import { ADD_DESIGNATION_LEVEL_FAIL, ADD_DESIGNATION_LEVEL_PENDING, ADD_DESIGNATION_LEVEL_SUCCESS, DESIGNATION_LEVEL_FAIL, DESIGNATION_LEVEL_PENDING, DESIGNATION_LEVEL_SUCCESS, UPDATE_DESIGNATION_LEVEL_FAIL, UPDATE_DESIGNATION_LEVEL_PENDING, UPDATE_DESIGNATION_LEVEL_SUCCESS } from "../../actions/types";

const initialState = { 
    designationLevels: [],
    addDesignation_Level : '',
    updateDesignation_Level : '',
    };

const designationLevels_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case DESIGNATION_LEVEL_SUCCESS:
            return {
                ...state,
                designationLevels: payload.designationLevels,
            };
        case DESIGNATION_LEVEL_PENDING:
            return {
                ...state,
                designationLevels: payload.designationLevels,
            };
        case DESIGNATION_LEVEL_FAIL:
            return {
                ...state,
                designationLevels: []
            };
        case ADD_DESIGNATION_LEVEL_SUCCESS:
            return {
                ...state,
                addDesignation_Level: payload.addDesignation_Level,
            };
        case ADD_DESIGNATION_LEVEL_PENDING:
            return {
                ...state,
                addDesignation_Level: payload.addDesignation_Level,
            };
        case ADD_DESIGNATION_LEVEL_FAIL:
            return {
                ...state,
                addDesignation_Level: false
            };
        case UPDATE_DESIGNATION_LEVEL_SUCCESS:
            return {
                ...state,
                updateDesignation_Level: payload.updateDesignation_Level,
            };
        case UPDATE_DESIGNATION_LEVEL_PENDING:
            return {
                ...state,
                updateDesignation_Level: payload.updateDesignation_Level,
            };
        case UPDATE_DESIGNATION_LEVEL_FAIL:
            return {
                ...state,
                updateDesignation_Level: false
            };
        default:
            return state;
    }
}
export default designationLevels_reducer