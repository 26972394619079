import {
    VISITPROFILE_SUCCESS,
    VISITPROFILE_FAIL,
    SET_MESSAGE,
    VISITPROFILE_PENDING,
    ADD_VISITPROFILE_SUCCESS,
    ADD_VISITPROFILE_FAIL,
    ADD_VISITPROFILE_PENDING,
    UPDATE_VISITPROFILE_SUCCESS,
    UPDATE_VISITPROFILE_FAIL,
    UPDATE_VISITPROFILE_PENDING,
    VISITPROFILE_DETAIL_FAIL,
    VISITPROFILE_DETAIL_PENDING,
    VISITPROFILE_DETAIL_SUCCESS
} from "../../actions/types";

const initialState = {
    visitProfile: [],
    visitProfileDetail: [],
    addvisitProfile: '',
    updatevisitProfile: ''
};

const visitProfile_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case VISITPROFILE_SUCCESS:
            return {
                ...state,
                visitProfile: payload.visitProfile,
            };
        case VISITPROFILE_PENDING:
            return {
                ...state,
                visitProfile: payload.visitProfile,
            };
        case VISITPROFILE_FAIL:
            return {
                ...state,
                visitProfile: []
            };

        case VISITPROFILE_DETAIL_SUCCESS:
            return {
                ...state,
                visitProfileDetail: payload.visitProfileDetail,
            };
        case VISITPROFILE_DETAIL_PENDING:
            return {
                ...state,
                visitProfileDetail: payload.visitProfileDetail,
            };
        case VISITPROFILE_DETAIL_FAIL:
            return {
                ...state,
                visitProfileDetail: []
            };
        case ADD_VISITPROFILE_SUCCESS:
            return {
                ...state,
                addvisitProfile: payload.addvisitProfile,
            };
        case ADD_VISITPROFILE_PENDING:
            return {
                ...state,
                addvisitProfile: payload.addvisitProfile,
            };
        case ADD_VISITPROFILE_FAIL:
            return {
                ...state,
                addvisitProfile: false
            };
        case UPDATE_VISITPROFILE_SUCCESS:
            return {
                ...state,
                updatevisitProfile: payload.updatevisitProfile,
            };
        case UPDATE_VISITPROFILE_PENDING:
            return {
                ...state,
                updatevisitProfile: payload.updatevisitProfile,
            };
        case UPDATE_VISITPROFILE_FAIL:
            return {
                ...state,
                updatevisitProfile: false
            };
        default:
            return state;
    }
}
export default visitProfile_reducer