import React from 'react';
import PropTypes from 'prop-types';
import './BodyStyle.css'; // Import the CSS file

const BodySmallRegular = ({ text, color, textAlign = 'left' }) => {
  return (
    <p className="body-small-regular"  style={{ color: color, textAlign: textAlign }}>
      {text}
    </p>
  );
};

BodySmallRegular.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default BodySmallRegular;