import React, { useState } from 'react';
import './CustomSubordinateSelect.css';

const CustomSubordinateSelect = ({ options, value, onChange, disabled = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(value);

    const handleToggleDropdown = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option.label);
        onChange(option);
        setIsOpen(false);
    };

    return (
        <div className={`custom-subordinate-select-container ${disabled ? 'custom-disabled' : ''}`}>
            <div className="custom-subordinate-select" onClick={handleToggleDropdown}>
                <span className='main-label-container'>{selectedOption || 'Employee'}</span>
                <span className="custom-subordinate-select-arrow">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#4B5563"
                        className={`w-5 h-5 ${isOpen ? 'rotate-180' : ''}`}
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </span>
            </div>
            {isOpen && (
                <div className="custom-subordinate-select-options">
                    {options && options.length > 0 ? (
                        options.map((option) => (
                            <div
                                key={option.value}
                                className="custom-subordinate-select-option"
                                onClick={() => handleOptionClick(option)}
                            >
                                <span>{option.labelWithDesigation}</span>
                            </div>
                        ))
                    ) : (
                        <div className="custom-subordinate-select-option">
                            <span>No data</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default CustomSubordinateSelect;
