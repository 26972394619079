import React from 'react';
import PropTypes from 'prop-types';
import './BodyStyle.css';

const BodyMediumRegular = ({ text, color, textAlign = 'left' }) => {
  return (
    <p className="body-medium-regular" style={{ color: color, textAlign: textAlign }}>
      {text}
    </p>
  );
};

BodyMediumRegular.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

export default BodyMediumRegular;
