import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getTaskDetails, addTaskNotes,
  getTaskNotes,
} from "../../actions/userActions/tasks/taskAction";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../subComponents/Loader";
import { GOOGLE_MAP_API_KEY } from "../../Constatns.js";
import "../tasks/TaskDetail.css";
import moment from "moment";
import "./TaskDetail.css";
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import Tooltip from '@mui/material/Tooltip';
import TextField from "@mui/material/TextField";
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Button } from "@mui/material";
import {
  updateSubTask
} from "../../actions/userActions/tasks/taskAction";
import { Drawer } from "@mui/material";
import { Slide } from "@mui/material";
import { format } from "date-fns";
// import Button from "react-bootstrap/Button";
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import HrComponent from "../reuse_components/HrComponent";
import MyMap from "../gMap/MyMap";
import GMap from "../gMap/GMap";

const TaskDetail = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editableFieldId, setEditableFieldId] = useState(null);
  const [statusHistory, setStatusHistory] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Sub Tasks");
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const rowData = location.state;
  const icon =
    "https://cdn2.iconfinder.com/data/icons/social-media-8/512/pointer.png";

  const [loadMap, setLoadMap] = useState(false);
  const [remarks, setRemarks] = useState('');

  const loadGoogleMapScript = (callback) => {
    if (
      typeof window.google === "object" &&
      typeof window.google.maps === "object"
    ) {
      callback();
    } else {
      const googleMapScript = document.createElement("script");
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener("load", callback);
    }
  };

  useEffect(() => {
    dispatch(getTaskDetails(rowData?.id));
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  const SubTasksChange = () => {
    setStatusHistory(false);
    setSelectedTab("Sub Tasks");
  };
  const StatusHistoryChange = () => {
    setStatusHistory(true);
    setSelectedTab("Status History");
  };

  const taskDetails = useSelector((state) => state.tasks_reducer.taskDetails);
  const markerList = [];

  taskDetails.length !== 0 &&
    taskDetails !== "isLoading" &&
    taskDetails.taskLog &&
    taskDetails.taskLog.map((e) => {
      if (e.longitude != null && e.latitude != null) {
        markerList.push({
          lat: e.latitude,
          lng: e.longitude,
          icon: icon,
          title: e.taskStatusName,
        });
      }
    });
  const handleEditButtonClick = (subTaskId) => {
    setEditableFieldId(subTaskId);
  };
  const handleCancelClick = () => {
    setEditableFieldId(null);
  };
  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };

  const handleRemarksSubmit = (subTask) => {
    setLoading(true);
    const body = {
      taskId: subTask.taskId,
      subTaskId: subTask.subTaskId,
      remarks: remarks,
      subTaskName: subTask.subTaskName,
    };
    try {
      dispatch(updateSubTask(body)).then(() => {
        setLoading(false);
        dispatch(getTaskDetails(subTask.taskId));
        setEditableFieldId(null);
      });
    } catch (error) {
      setLoading(false);
      console.error("Error submitting subtask:", error);
    }
  };


  const [drawerVisible, setDrawerVisible] = useState(false);
  const [state, setState] = useState({
    right: false,
  });
  const [taskNote, setTaskNote] = useState("");

  const [newTaskId, setNewTaskId] = useState();

  const toggleDrawer = (anchor, open, id) => (event) => {

    if (id != null && id != undefined) {
      dispatch(getTaskNotes(id));
    }
    setNewTaskId(id);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      setDrawerVisible(true);
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const getNotes = useSelector((state) => state.tasks_reducer.taskNotes);
  const handleCloseDrawer = () => {
    setState({ ...state, right: false });
  };
  const list = (id) => {
    return (
      <div
        role="presentation"
        onClick={(event) => {
          event.stopPropagation();
        }}
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
      >
        <div>
          <div className="d-flex justify-content-between align-items-center mr-3">
            <p className="heading_notes p-5">Notes</p>
            <Tooltip title="Close Notes" arrow>
              <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseDrawer} />
            </Tooltip>
          </div>
          <hr />
          {getNotes === "isLoading"}

          {getNotes === "isLoading" &&
            getNotes.length > 0 &&
            (!getNotes.createdByName ||
              !getNotes.createdDate ||
              !getNotes.note) && <Loader colored={true} />}

          {getNotes !== "isLoading" &&
            getNotes !== undefined &&
            getNotes.map((noteItem) => {
              const nameParts = noteItem.createdByName.split(" ");
              const firstNameInitial = nameParts[0] ? nameParts[0][0] : "";
              const lastNameInitial =
                nameParts.length > 1
                  ? nameParts[nameParts.length - 1][0]
                  : "";

              const formattedDate = format(
                new Date(noteItem.createdDate),
                "EEEE MMMM d, yyyy"
              );

              return (
                <>
                  <div style={{ marginTop: "40px", marginBottom: "53px" }}>
                    <div className="notes-avatar-wrapper" id="navbardrop">
                      <p>
                        {firstNameInitial}
                        {lastNameInitial}
                      </p>
                    </div>
                    <div style={{ marginLeft: "67px", marginTop: "-49px" }}>
                      <p className="no-line-break notes_name">
                        {noteItem.createdByName}
                      </p>
                      <p>{formattedDate}</p>
                    </div>
                    <p
                      className="notes_paragraph"
                      style={{ marginBottom: "35px" }}
                    >
                      {noteItem.note}
                    </p>
                  </div>
                </>
              );
            })}

          <div
            style={{
              position: "fixed",
              bottom: "0px",
              width: "100%",
              backgroundColor: "white",
              zIndex: "999",
            }}
          >
            <TextField
              style={{ width: "28%", margin: "10px" }}
              multiline
              rows={3}
              value={taskNote}
              onChange={(e) => setTaskNote(e.target.value)}
              placeholder="Enter Task Note"
            />
            <br />
            <Button
              style={{ width: "28%", margin: "9px" }}
              onClick={() => {
                handleAddNote(id);
              }}
            >
              Add Note
            </Button>
          </div>
        </div>
      </div>
    );
  };
  const handleAddNote = async (id) => {
    if (taskNote.trim() !== "") {
      const noteBody = {
        taskId: id,
        note: taskNote,
        createdDate: new Date().toISOString(),
        createdBy: user.result[0].user.id,
        updatedDate: new Date().toISOString(),
        updatedBy: user.result[0].user.id,
      };

      try {
        dispatch(addTaskNotes(noteBody)).then(() => {
          dispatch(getTaskNotes(id));
        })

        setTaskNote("");
      } catch (error) {
        console.error(
          "An error occurred while adding or fetching notes:",
          error
        );
      }
    } else {
      alert("Please enter a note before adding.");
    }
  };
  return (
    <>
      <div>
        <div className="row">
          <div
            className="col-md-9"
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <i onClick={history.goBack}>
              <svg
                style={{ marginBottom: "0px", cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M20 11.5H7.83L13.42 5.91L12 4.5L4 12.5L12 20.5L13.41 19.09L7.83 13.5H20V11.5Z"
                  fill="#505157"
                />
              </svg>
            </i>{" "}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h2
                className="d-inline-block"
                style={{
                  width: "153px",
                  height: "33px",
                  fontFamily: "Roboto",
                  fontSize: "28px",
                  fontWeight: "500",
                  lineHeight: "33px",
                  textAlign: "left",
                  color: "#272727",
                }}
              >
                Task Details
              </h2>
            </div>
          </div>
          <div className="col-md-3 d-flex justify-content-end">
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                {taskDetails !== "isLoading" &&
                  taskDetails != undefined &&
                  taskDetails.length !== 0 && (
                    <>
                      <Button
                        id="basic-button"
                        onClick={toggleDrawer(anchor, true, taskDetails.id)}
                        startIcon={<VisibilityOutlinedIcon />}
                        sx={{
                          color: "#505157",
                          border: "1px solid #D9D9D9",
                          backgroundColor: "#FFFFFF",
                          boxShadow: "2px #00000004",
                          paddingLeft: "15px",
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                          "& .MuiButton-startIcon": {
                            margin: "0",
                          },
                          "&:hover": {
                            color: "#2F54EB",
                            borderColor: "#2F54EB",
                            "& .MuiButton-startIcon": {
                              color: "#2F54EB",
                            },
                          },
                        }}
                      >
                        View Notes
                      </Button>
                      <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        TransitionComponent={Slide}
                        transitionDuration={500}
                        transitionprops={{ direction: "right" }}
                        PaperProps={{ style: { width: "30%" } }}
                        BackdropProps={{
                          style: { backgroundColor: "transparent" },
                        }}
                      >
                        {list(taskDetails.id)}
                      </Drawer>
                    </>
                  )}
              </React.Fragment>
            ))}
          </div>
          <div className="col-12">
            <HrComponent />
          </div>
        </div>
        {taskDetails === "isLoading" && (
          <div colSpan={12} style={{ backgroundColor: "#fff !important" }}>
            <Loader colored={true} />
          </div>
        )}

        {taskDetails !== "isLoading" &&
          taskDetails != undefined &&
          taskDetails.length !== 0 && (
            <div className="detail-container mt-5 ml-2">
              <div className="row">
                <div className="col-md-2" style={{ width: "auto" }}>
                  <div className="set_text_gap">
                    <p className="details_text">Doctor</p>
                    <p className="details_text">Destination</p>
                    <p className="details_text">Assigned by</p>
                    <p className="details_text">Assigned to</p>
                    <p className="details_text">Created on</p>
                  </div>
                </div>
                <div className="col-md-3 ml-2" style={{ width: "auto" }}>
                  <div className="set_text_gap">
                    <p className="label_data">
                      {" "}
                      {taskDetails.doctorName
                        ? taskDetails.doctorName
                        : "Not available"}
                    </p>
                    <p className="label_data">
                      {" "}
                      {taskDetails.practiceLocationName
                        ? taskDetails.practiceLocationName
                        : "Not available"}
                    </p>
                    <p className="label_data">
                      {taskDetails.assignedByName
                        ? taskDetails.assignedByName
                        : "Not available"}
                    </p>
                    <p className="label_data">
                      {" "}
                      {taskDetails.assignedToName
                        ? taskDetails.assignedToName
                        : "Not available"}
                    </p>
                    <p className="label_data">
                      {taskDetails.createdAt
                        ? moment(taskDetails.createdAt).format("MMM DD, YYYY")
                        : "John Doe"}
                    </p>
                  </div>
                </div>
                <div className="col-md-2 ml-5" style={{ width: "auto" }}>
                  <div className="set_text_gap">
                    <p className="details_text">Status</p>
                    <p className="details_text">Priority</p>
                    <p className="details_text">Products</p>
                    <p className="details_text">Accompanied By</p>
                  </div>
                </div>
                <div className="col-md-3 ml-2">
                  <div className="set_text_gap">
                    <p className="badge-lg badge-dot label_data">
                      <i className="bg-success" />
                      {taskDetails.statusName}
                    </p>
                    <p className="label_data">
                      <span
                        style={{
                          color: taskDetails.priorityColor,
                          height: "28px",
                          borderRadius: "4px",
                          width: "auto",
                          marginRight: "10px",
                          padding: "4px 8px 4px 8px",
                          backgroundColor: "#FF4D001A",
                        }}
                      >
                        {taskDetails.priorityName}
                      </span>
                    </p>
                    <p className="label_data">
                      {taskDetails.taskProducts &&
                        taskDetails.taskProducts.length > 0 &&
                        taskDetails.taskProducts.map((product, index) => (
                          <span key={index}>
                            {index > 0 && ", "}
                            {product.productName}
                          </span>
                        ))}
                    </p>
                    <p className="label_data">
                      <span>
                        {taskDetails.accompaniedBy && taskDetails.accompaniedBy.length > 0 ? (
                          taskDetails.accompaniedBy.map((accompaniedBy, index) => (
                            <span key={index}>
                              {index > 0 && ", "}
                              {accompaniedBy.accompaniedByName}
                            </span>
                          ))
                        ) : (
                          "N/A"
                        )}
                      </span> </p>
                </div>
              </div>
            </div>

              {taskDetails.taskSubTasks && (
          <div className="row" style={{ marginTop: "24px" }}>
            <div>
              <div className="row">
                <Tabs
                  value={selectedTab}
                  onChange={(e, newValue) => setSelectedTab(newValue)}
                >
                  <Tab
                    label="Sub Tasks"
                    value="Sub Tasks"
                    onClick={SubTasksChange}
                  />
                  <Tab
                    label="Status History"
                    value="Status History"
                    onClick={StatusHistoryChange}
                  />
                </Tabs>
              </div>
              {/* <h5 className="d-inline-block" style={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    textAlign: "left",
                    textAlign: "left",
                    color: "#000000"
                  }}>Sub Tasks</h5> */}
              <HrComponent />
            </div>
            <div className="row">
              {statusHistory != true &&
                taskDetails.taskSubTasks.map((subTask) => (
                  <div className="col-6 mb-2" key={subTask.subTaskId}>
                    <div
                      className="col-md-12 mt-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p className="details_text">
                        {subTask.subTaskName
                          ? subTask.subTaskName
                          : "Not available"}
                      </p>
                      <Tooltip title="Edit Note" arrow>
                        <button
                          style={{
                            width: "24px",
                            height: "24px",
                            background: "#FFFFFF",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleEditButtonClick(subTask.subTaskId)
                          }
                        >
                          <EditNoteOutlinedIcon />
                        </button>
                      </Tooltip>
                    </div>
                    <div className="col-12 mt-2">
                      <Grid container>
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                          <TextField
                            id={`multiline-flexible-${subTask.subTaskId}`}
                            multiline
                            maxRows={3}
                            variant="standard"
                            defaultValue={subTask.remarks}
                            disabled={
                              editableFieldId !== subTask.subTaskId
                            }
                            fullWidth
                            onChange={(event) =>
                              handleRemarksChange(
                                event,
                                subTask.subTaskId
                              )
                            }
                            placeholder="add remarks about the subtask here"
                            InputProps={{
                              style: {
                                backgroundColor: "#F5F5F5",
                                borderRadius: "4px",
                                padding: "10px",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "22px",
                                textAlign: "left",
                                color: "#000000",
                              },
                              disableUnderline: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    {editableFieldId === subTask.subTaskId && (
                      <div
                        className="mt-5"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button
                          onClick={() => handleRemarksSubmit(subTask)}
                          style={{
                            width: "77px",
                            height: "32px",
                            padding: "2px 14px 4px 14px",
                            gap: "8px",
                            borderRadius: "4px",
                            border: "1px",
                            background: "#002D72",
                            border: "1px solid #002D72",
                            boxShadow: "2px #0000000B",
                            color: "white",
                          }}
                          disabled={loading}
                        >
                          {loading ? (
                            <Loader />
                          ) : subTask.remarks ? (
                            "Update"
                          ) : (
                            "Add"
                          )}
                        </button>
                        <button
                          onClick={handleCancelClick}
                          style={{
                            width: "75px",
                            height: "32px",
                            padding: "2px 14px 4px 14px",
                            gap: "10px",
                            borderRadius: "4px",
                            border: "1px",
                            background: "#FFFFFF",
                            boxShadow: "2px #00000004",
                            border: "1px solid #D9D9D9",
                            marginLeft: "16px",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}

        {statusHistory == true && taskDetails.taskLog && (
          <div className="row justify-content-center mr-2 ml-2">
            {taskDetails.taskLog.map((log) => (
              <div
                className="row mt-4"
                key={log.id}
                style={{
                  border: "1px solid #BFBFBF",
                  padding: "16px",
                  gap: "14px",
                  borderRadius: "6px",
                }}
              >
                <div className="col-md-3" style={{ width: "auto" }}>
                  <div className="set_text_gap">
                    <p className="details_text">Status</p>
                  </div>
                </div>
                <div className="col-md-3 ml-2" style={{ width: "auto" }}>
                  <div className="set_text_gap">
                    <p className="badge-lg badge-dot label_data">
                      <i className="bg-success" />
                      {log.taskStatusName
                        ? log.taskStatusName
                        : "Not available"}
                    </p>
                  </div>
                </div>
                <div className="col-md-3 ml-4" style={{ width: "auto" }}>
                  <div className="set_text_gap">
                    <p className="details_text">Updated by</p>
                  </div>
                </div>
                <div className="col-md-3 ml-2" style={{ width: "auto" }}>
                  <div className="set_text_gap">
                    <p className="label_data">
                      {log.updatedByName
                        ? log.updatedByName
                        : "Not available"}
                    </p>
                  </div>
                </div>
                <div className="col-md-3 ml-4" style={{ width: "auto" }}>
                  <div className="set_text_gap">
                    <p className="details_text">Updated on</p>
                  </div>
                </div>
                <div className="col-md-3 ml-2" style={{ width: "auto" }}>
                  <div className="set_text_gap">
                    <p className="label_data">
                      {log.updatedDate
                        ? moment.utc(log.updatedDate).local().format('lll')
                        : "Not available"}
                    </p>
                  </div>
                </div>

                {log.latitude && log.longitude ? (
                  <div
                    className="col-12"
                    id="map"
                    style={{ marginTop: "0px", height: "200px" }}
                  >
                    {!loadMap ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      <GMap markerList={markerList} />
                    )}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )}
      </div>
          )}
    </div >
    </>
  );
};

export default TaskDetail;
