import React, { useState, useRef } from "react";
import "./PlannerAccordian.css";
import BodyMediumMedium from "../designSystem/BodyMediumMedium";
import BodySmallRegular from "../designSystem/BodySmallRegular";

const PlannerAccordian = ({ plan }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const toggleAccordion = () => {
        setIsExpanded((prev) => !prev);
    };
    const contentRef = useRef(null);
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: "numeric", month: "short", day: "2-digit" };
        return date.toLocaleDateString("en-US", options);
    };

    return (
        <div className="accordian-container">
            <div className="header-container">
                <BodyMediumMedium text={formatDate(plan?.planDate)} color="#1E293B" />
                <button
                    className="accordian-button"
                    onClick={toggleAccordion}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#4B5563"
                        className={`w-5 h-5 transform transition-transform ${isExpanded ? "rotate-180" : "rotate-0"
                            }`}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                    </svg>
                </button>
            </div>
            <div
                className="accordian-content-wrapper"
                style={{
                    height: isExpanded ? `${contentRef.current?.scrollHeight}px` : "0px",
                    overflow: "hidden",
                    transition: "height 0.3s ease-in-out",
                }}
            >
                <div ref={contentRef} className="accordian-content">

                    {plan && plan.planDetails.length !== 0 &&
                        [...plan.planDetails]
                            .sort((a, b) => {
                                const timeA = a.startTime.split(':').map(Number);
                                const timeB = b.startTime.split(':').map(Number);
                                return timeA[0] * 60 + timeA[1] - (timeB[0] * 60 + timeB[1]);
                            })
                            .map((item, index) => {

                                const formatTime = (timeString) => {
                                    const [hours, minutes] = timeString.split(':');
                                    const hour = parseInt(hours, 10);
                                    const period = hour >= 12 ? 'PM' : 'AM';
                                    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
                                    return `${formattedHour}:${minutes} ${period}`;
                                };

                                return (
                                    <div key={index}>
                                        <hr />
                                        <div className="accordian-row">
                                            <div className="col-3">
                                                <BodySmallRegular text={`${formatTime(item.startTime)} to ${formatTime(item.endTime)}`} color="#637381" />
                                            </div>
                                            <div className="col-3">
                                                <BodySmallRegular text={item.doctorName ? item.doctorName : item.cityName} color="#637381" />
                                            </div>
                                            <div className="col-6">
                                                <BodySmallRegular text={item.practiceLocationName} color="#637381" />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                </div>
            </div>
        </div>
    );
};

export default PlannerAccordian;
