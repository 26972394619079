import { ADD_DESIGNATION_LEVEL_FAIL, ADD_DESIGNATION_LEVEL_PENDING, ADD_DESIGNATION_LEVEL_SUCCESS, DESIGNATION_LEVEL_FAIL, DESIGNATION_LEVEL_PENDING, DESIGNATION_LEVEL_SUCCESS, UPDATE_DESIGNATION_LEVEL_FAIL, UPDATE_DESIGNATION_LEVEL_PENDING, UPDATE_DESIGNATION_LEVEL_SUCCESS } from "../types";

import { toast } from "react-toastify";
import UserService from "../../services/user.service";

export const getDesignationsLevel = (pageNumber, pageSize, searchQuery) => async (dispatch) => {
    dispatch({
        type: DESIGNATION_LEVEL_PENDING,
        payload: { designationLevels: "isLoading" },
    });
    return await UserService.getDesignationsLevel(pageNumber, pageSize, searchQuery)
        .then(
            (data) => {
                data.data.status
                    ? dispatch({
                        type: DESIGNATION_LEVEL_SUCCESS,
                        payload: { designationLevels: data.data },
                    })
                    : dispatch({
                        type: DESIGNATION_LEVEL_FAIL,
                    });
                    return Promise.resolve();
            },
            (error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                dispatch({
                    type: DESIGNATION_LEVEL_FAIL,
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });

                return Promise.reject();
            }
        )
        .catch((error) => {
            return Promise.reject();
        });
};

// Add designation LEVEL
export const addDesignationLevel = (body) => async (dispatch) => {
    dispatch({
        type: ADD_DESIGNATION_LEVEL_PENDING,
        payload: { addDesignation_Level: "isLoading" },
    });
    return await UserService.addDesignationLevel(body)
        .then(
            (data) => {
                data.data.status
                    ? toast.success("Designation Level added Successfully !", {
                        autoClose: 2000,
                    })
                    : toast.error(data.data.message, { autoClose: 2000 });
                data.data.status
                    ? dispatch({
                        type: ADD_DESIGNATION_LEVEL_SUCCESS,
                        payload: { addDesignation_Level: data.data.status },
                    })
                    : dispatch({
                        type: ADD_DESIGNATION_LEVEL_FAIL,
                        payload: { addDesignation_Level: data.data.status },
                    });
                return Promise.resolve();
            },
            (error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                dispatch({
                    type: ADD_DESIGNATION_LEVEL_FAIL,
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });

                return Promise.reject();
            }
        )
        .catch((error) => {
        }).finally(() => {
            dispatch({
                type: ADD_DESIGNATION_LEVEL_FAIL,
            });
        });
};

// Update Designations LEVEL
export const updateDesignationLevel = (body) => async (dispatch) => {
    dispatch({
        type: UPDATE_DESIGNATION_LEVEL_PENDING,
        payload: { updateDesignation_Level: "isLoading" },
    });
    return await UserService.updateDesignationLevel(body)
        .then(
            (data) => {
                data.data.status
                    ? toast.success("Designation updated Successfully !", {
                        autoClose: 2000,
                    })
                    : toast.error(data.data.message, { autoClose: 2000 });
                data.data.status
                    ? dispatch({
                        type: UPDATE_DESIGNATION_LEVEL_SUCCESS,
                        payload: { updateDesignation_Level: data.data.status },
                    })
                    : dispatch({
                        type: UPDATE_DESIGNATION_LEVEL_FAIL,
                        payload: { updateDesignation_Level: data.data.status },
                    });
                return Promise.resolve();
            },
            (error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                dispatch({
                    type: UPDATE_DESIGNATION_LEVEL_FAIL,
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });

                return Promise.reject();
            }
        )
        .catch((error) => {
        }).finally(() => {
            dispatch({
                type: UPDATE_DESIGNATION_LEVEL_FAIL,
            });
        });
};
