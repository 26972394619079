import React from 'react';
import './Modal.css';
import rejectPlanIcon from "../../assets/images/rejectPlanIcon.svg";
import Heading6Semibold from '../designSystem/Heading6Semibold';
import BodyMediumRegular from '../designSystem/BodyMediumRegular';
import BodyExtraSmallMedium from '../designSystem/BodyExtraSmallMedium';

const RejectPlanModal = ({ isVisible, onClose, onRejectClick, isLoading }) => {

    return (
        isVisible && (
            <div className="modal-backdrop">
                <div className="modal-container" onClick={(e) => e.stopPropagation()}>
                    <div className='content-container'>
                        <div className='icon-container'>
                            <img src={rejectPlanIcon} />
                        </div>
                        <div className='text-container'>
                            <Heading6Semibold color='#1E293B' text='Reject Plan' />
                            <BodyMediumRegular
                                color='#384153'
                                text='Are you sure you want to reject this monthly plan? The employee will need to revisit and resubmit the plan for review.'
                                textAlign='center'
                            />
                        </div>
                    </div>
                    <div className='button-contiainer'>
                        <button className='cancel-button'
                            onClick={onClose}
                        >
                            <BodyExtraSmallMedium color='#1E293B' text='Cancel' />
                        </button>
                        <button className='danger-button' onClick={onRejectClick} disabled={isLoading}>
                            <BodyExtraSmallMedium color='#FFFFFF' text='Reject' />
                        </button>

                    </div>
                </div>
            </div>
        )
    );
};

export default RejectPlanModal;
