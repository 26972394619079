import React from "react";
import "./PlannerTableView.css";
import BodySmallMedium from "../designSystem/BodySmallMedium";
import downArrowIcon from "../../assets/images/downArrowIcon.svg";
import BodySmallRegular from "../designSystem/BodySmallRegular";

const PlannerTableView = ({ planDates }) => {
    const HEADOFFICE = "Head Office";
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: "numeric", month: "short", day: "2-digit" };
        return date.toLocaleDateString("en-US", options);
    };
    return (
        <div className="planner-table-container">
            <table className="planner-table">
                <thead>
                    <tr>
                        <th className="header-colmn">
                            <div className="colmn-container">
                                <BodySmallMedium text="Date" color="#1E293B" />
                                <img src={downArrowIcon} />
                            </div>

                        </th>
                        <th className="header-colmn">
                            <div className="colmn-container">
                                <BodySmallMedium text="City" color="#1E293B" />
                                <img src={downArrowIcon} />
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {planDates && planDates.length > 0 ? (
                        planDates
                            .sort((a, b) => new Date(a.planDate) - new Date(b.planDate))
                            .map((plan) => (
                                <tr key={plan.id}>
                                    <td>
                                        <BodySmallRegular text={formatDate(plan?.planDate)} color="#1E293B" />
                                    </td>
                                    <td>
                                        <BodySmallRegular
                                            text={
                                                plan.planDetails[0]?.cityName
                                                    ? plan.planDetails[0].cityName
                                                    : plan.planDetails[0]?.headOffice
                                                        ? HEADOFFICE
                                                        : "N/A"
                                            }
                                            color="#1E293B"
                                        />
                                    </td>
                                </tr>
                            ))
                    ) : (
                        <tr>
                            <td colSpan="2" style={{ textAlign: "center", padding: "20px" }}>
                                <BodySmallRegular textAlign="center" text="No Data" color="#1E293B" />
                            </td>
                        </tr>
                    )}

                </tbody>
            </table>
        </div>

    )

}
export default PlannerTableView;