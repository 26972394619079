
import React from 'react';
import './ViewDrawer.css';  // Styles for the drawer
import BodyLargeMedium from '../designSystem/BodyLargeMedium';
import closeIcon from "../../assets/images/closeIcon.svg"
import EventCard from './EventCard';

const ViewDrawer = ({ isOpen, onClose, day, events }) => {
    const formattedDate = day
        ? new Intl.DateTimeFormat('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        }).format(new Date(day.year, day.month - 1, day.day))
        : '';
    return (
        <>
            {/* Backdrop */}
            <div
                className={`view-drawer-backdrop ${isOpen ? 'open' : ''}`}
                onClick={onClose}
            ></div>

            {/* Drawer */}
            <div className={`view-drawer-container ${isOpen ? 'open' : ''}`}>

                <div className="view-drawer-header">
                    <BodyLargeMedium text={formattedDate} color='#384153' />
                    <button className='close-icon' onClick={onClose}>
                        <img src={closeIcon} />
                    </button>
                </div>
                <div className='view-drawer-main'>
                    {isOpen && events && events.planDetails && events.planDetails.length !== 0 &&
                        events.planDetails.map((plan) => (
                            <EventCard key={plan.id} event={plan} />
                        ))}
                </div>
            </div>
        </>
    );
};

export default ViewDrawer;
