import {
    DOCTOR_SUCCESS,
    DOCTOR_FAIL,
    DOCTOR_PENDING,
    ADD_DOCTOR_SUCCESS,
    ADD_DOCTOR_FAIL,
    ADD_DOCTOR_PENDING,
    UPDATE_DOCTOR_SUCCESS,
    UPDATE_DOCTOR_FAIL,
    UPDATE_DOCTOR_PENDING,
    DOCTOR_PRACTICELOCATION_SUCCESS,
    DOCTOR_PRACTICELOCATION_FAIL,
    DOCTOR_PRACTICELOCATION_PENDING


} from "../../actions/types";

const initialState = { 
    getDoctors :[],
    getDoctors :[],
    loadingDoctors:false,
    doctors: [],
    addDoctors :'',
    updateDoctors : ''
    };

const doctors_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case DOCTOR_SUCCESS:
            return {
                ...state,
                doctors: payload.doctors,
            };
        case DOCTOR_PENDING:
            return {
                ...state,
                doctors: payload.doctors,
            };
        case DOCTOR_FAIL:
            return {
                ...state,
                doctors: []
            };
            case DOCTOR_PRACTICELOCATION_SUCCESS:
                console.log('Doctors fetched successfully:', payload.getDoctors);
                const data = [...state.getDoctors,  {doctors: payload.getDoctors[0]?.doctors, practiceLocationId : payload.practiceLocationId}];
                return {
                    
                    ...state,
                    getDoctors: data.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                          t.practiceLocationId === value.practiceLocationId
                        ))
                    ),
                    
                    loadingDoctors: false
                };
            case DOCTOR_PRACTICELOCATION_PENDING:
                return {
                    ...state,
                    loadingDoctors: payload.loadingDoctors,
                };
            case DOCTOR_PRACTICELOCATION_FAIL:
                const data2 = [...state.getDoctors,  {doctors: [], practiceLocationId : payload.practiceLocationId}];
                return {
                    
                    ...state,
                    getDoctors: data2.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                          t.practiceLocationId === value.practiceLocationId
                        ))
                    ),
                    
                    loadingDoctors: false
                };
        case ADD_DOCTOR_SUCCESS:
            return {
                ...state,
                addDoctors: payload.addDoctors,
            };
        case ADD_DOCTOR_PENDING:
            return {
                ...state,
                addDoctors: payload.addDoctors,
            };
        case ADD_DOCTOR_FAIL:
            return {
                ...state,
                addDoctors: false
            };
        case UPDATE_DOCTOR_SUCCESS:
                return {
                    ...state,
                    updateDoctors: payload.updateDoctors,
                };
        case UPDATE_DOCTOR_PENDING:
                return {
                    ...state,
                    updateDoctors: payload.updateDoctors,
                };
        case UPDATE_DOCTOR_FAIL:
                return {
                    ...state,
                    updateDoctors: false
                };
        default:
            return state;
    }
}
export default doctors_reducer