import React, { useEffect, useState } from 'react';
import "./Calendar.css";
import BodyExtraSmallMedium from '../designSystem/BodyExtraSmallMedium';
import BodySmallRegular from '../designSystem/BodySmallRegular';
import BodyMediumRegular from '../designSystem/BodyMediumRegular';
import chevron_leftIcon from "../../assets/images/chevron_leftCalendarIcon.svg"
import chevron_rightIcon from "../../assets/images/chevron_rightCalendarIcon.svg"
import { useDispatch, useSelector } from 'react-redux';
import { approveEmployeePlan, getEmployeePlan, rejectEmployeePlan, updatePlanStatus } from '../../actions/userActions/EmployeePlanAction';
import BodySmallMedium from '../designSystem/BodySmallMedium';
import SubmitPlanModal from './SubmitPlanModal';
import CustomSelect from './CustomSelect';
import { getEmployeesByLevel } from '../../actions/userActions/employeeAction';
import myplanIcon from "../../assets/images/myplanIcon.svg";
import myplanIconBlue from "../../assets/images/myplanIconBlue.svg";
import crossIconRed from "../../assets/images/crossIconRed.svg";
import tickIcon from "../../assets/images/tickIcon.svg";
import listIcon from "../../assets/images/listIcon.svg";
import listIconBlue from "../../assets/images/listIconBlue.svg";
import calendarIconBlue from "../../assets/images/calendarIconBlue.svg";
import calendarIcon from "../../assets/images/calendarIcon.svg"
import ApprovePlanModal from './ApprovePlanModal';
import RejectPlanModal from './RejectPlanModal';
import CustomSubordinateSelect from './CustomSubordinateSelect';

const NavigationHeader = ({ currentDate, selectedView, changeView, changeMonthOrWeek, formatMonth, planStatus, userId, planId, myPlan, setMyPlan, selectedEmployee, setSelectedEmployee }) => {
    const DAYVIEW = "timeGridDay";
    const WEEKVIEW = "timeGridWeek";
    const MONTHVIEW = "dayGridMonth";
    const LISTVIEW = "list";
    const [currentMonth, setCurrentMonth] = useState("");
    const permissions = JSON.parse(localStorage.getItem('permissionsList'));
    const [isSubmitPlanModalVisible, setIsSubmitPlanModalVisisble] = useState(false);
    const [isApprovePlanModalVisible, setIsApprovePlanModalVisisble] = useState(false);
    const [isRejectPlanModalVisible, setIsRejectPlanModalVisisble] = useState(false);
    const updatePlanStattuses = useSelector(state => state.employeePlan_reducer.updatePlanStatus);
    const updateEmployeePlanStatus = useSelector(state => state.employeePlan_reducer.updateEmployeePlan);
    const approvePlanStatus = useSelector(state => state.employeePlan_reducer.approveEmployeePlan);

    const rejectPlanStatus = useSelector(state => state.employeePlan_reducer.rejectEmployeePlan);
    const subordinates = useSelector((state) => state.employees_reducer.employeesByLevel);

    const dispatch = useDispatch();
    useEffect(() => {
        const newMonth = currentDate.getMonth() + 1;
        if (currentMonth !== newMonth) {
            setCurrentMonth(newMonth);
        }
    }, [currentDate, currentMonth]);
    useEffect(() => {
        if (myPlan && userId && currentDate) {
            const employeeId = userId;
            const month = currentDate.getMonth() + 1;
            const year = currentDate.getFullYear();
            dispatch(getEmployeePlan(employeeId, month, year));
        } else if (!myPlan && currentDate && selectedEmployee) {
            const employeeId = selectedEmployee;
            const month = currentDate.getMonth() + 1;
            const year = currentDate.getFullYear();
            dispatch(getEmployeePlan(employeeId, month, year));
        }
    }, [currentMonth, myPlan, selectedEmployee]);
    useEffect(() => {
        if ((updatePlanStattuses && updatePlanStattuses !== "isLoading" && updatePlanStattuses === true && userId && currentDate) || ((updateEmployeePlanStatus && updateEmployeePlanStatus !== "isLoading" && updateEmployeePlanStatus === true && userId && currentDate) || (approvePlanStatus && approvePlanStatus !== "isLoading" && approvePlanStatus === true && userId && currentDate) || (rejectPlanStatus && rejectPlanStatus !== "isLoading" && rejectPlanStatus === true && userId && currentDate))) {
            if (myPlan && userId && currentDate) {
                const employeeId = userId;
                const month = currentDate.getMonth() + 1;
                const year = currentDate.getFullYear();
                dispatch(getEmployeePlan(employeeId, month, year));
            } else if (!myPlan && currentDate && selectedEmployee) {
                const employeeId = selectedEmployee;
                const month = currentDate.getMonth() + 1;
                const year = currentDate.getFullYear();
                dispatch(getEmployeePlan(employeeId, month, year));
            }
            setIsSubmitPlanModalVisisble(false);
            setIsApprovePlanModalVisisble(false);
            setIsRejectPlanModalVisisble(false);
        }
    }, [currentMonth, updatePlanStattuses, updateEmployeePlanStatus, rejectPlanStatus, approvePlanStatus]);
    const handleSubmitPlan = () => {

        setIsSubmitPlanModalVisisble(true);
    }
    const handleSubmitClick = () => {
        if (planId) {
            dispatch(updatePlanStatus(planId));
        }
    }
    const handleApproveClick = () => {
        if (planId && planStatus === 2 && userId) {
            const statusId = 3;
            dispatch(approveEmployeePlan(planId, statusId, userId));
        }
    }

    const handleRejectClick = () => {
        if (planId && planStatus === 2 && userId) {
            const statusId = 4;
            dispatch(rejectEmployeePlan(planId, statusId, userId));
        }
    }

    const getStatusClass = (status) => {
        switch (status) {
            case 1:
                return "draft-status";
            case 2:
                return "pending-status";
            case 3:
                return "approved-status";
            case 4:
                return "rejected-status";
            default:
                return "default-status";
        }
    };
    const onSubmitPlanModalClose = () => {
        setIsSubmitPlanModalVisisble(false);
    };
    const onApprovePlanModalClose = () => {
        setIsApprovePlanModalVisisble(false);
    };
    const onRejectPlanModalClose = () => {
        setIsRejectPlanModalVisisble(false);
    };

    useEffect(() => {
        if (userId) {
            const searchQuery = "";
            const pageNumber = -1;
            const pageSize = 10;
            const employeeId = userId;
            dispatch(getEmployeesByLevel(employeeId, searchQuery, pageNumber, pageSize));
        }
    }, [userId]);
    const handleEmployeeChange = (event) => {
        const selectedEmployeeId = event.value;
        setSelectedEmployee(selectedEmployeeId);
        setMyPlan(false);
    };
    const handleMyPlan = () => {
        setMyPlan(true);
        setSelectedEmployee(null);
    };
    const handleApprovePlan = () => {
        setIsApprovePlanModalVisisble(true);
    };
    const handleRejectPlan = () => {
        setIsRejectPlanModalVisisble(true);
    };
    const options = subordinates && subordinates !== "isLoading" && subordinates.length > 0 && subordinates.map((subordinate) => ({
        value: subordinate.id,
        labelWithDesigation: (
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: "5px", alignItems: "center" }}>
                <span>{`${subordinate.firstName} ${subordinate.lastName}`}</span>
                <span style={{ color: "#637381", fontSize: "12px" }}>{subordinate.designationShortName}</span>
            </div>
        ),
        label: (
            <span>{`${subordinate.firstName} ${subordinate.lastName}`}</span>
        ),
    }));
    return (
        <>
            <div className="calendar-header">
                <div className="navigation-container">
                    <div className="button-container">
                        <button
                            onClick={() => changeView(MONTHVIEW)}
                            className={`custom-button ${selectedView === MONTHVIEW ? "active" : ""}`}
                        >
                            <BodySmallRegular
                                text="Month"
                                color={selectedView === MONTHVIEW ? "#006CFA" : "#384153"}
                            />
                        </button>
                        <button
                            onClick={() => changeView(WEEKVIEW)}
                            className={`custom-button ${selectedView === WEEKVIEW ? "active" : ""}`}
                        >
                            <BodySmallRegular
                                text="Week"
                                color={selectedView === WEEKVIEW ? "#006CFA" : "#384153"}
                            />
                        </button>
                        <button
                            onClick={() => changeView(DAYVIEW)}
                            className={`custom-button ${selectedView === DAYVIEW ? "active" : ""}`}
                        >
                            <BodySmallRegular
                                text="Day"
                                color={selectedView === DAYVIEW ? "#006CFA" : "#384153"}
                            />
                        </button>
                    </div>
                    <div className='calendar-list-button-container'>
                        <button
                            onClick={() => changeView(MONTHVIEW)}
                            className={`custom-button-icon ${(selectedView === MONTHVIEW || selectedView === WEEKVIEW || selectedView === DAYVIEW) ? "active" : ""}`}
                        >
                            <img src={selectedView === LISTVIEW ? calendarIcon : calendarIconBlue} />
                        </button>
                        <button
                            onClick={() => changeView(LISTVIEW)}
                            className={`custom-button-icon ${selectedView === LISTVIEW ? "active" : ""}`}
                        >
                            <img src={selectedView === LISTVIEW ? listIconBlue : listIcon} />
                        </button>

                    </div>
                    <div className="month-container">
                        <img
                            src={chevron_leftIcon}
                            alt="Previous"
                            className="icon"
                            onClick={() => changeMonthOrWeek(-1)}
                        />
                        <img
                            src={chevron_rightIcon}
                            alt="Next"
                            className="icon"
                            onClick={() => changeMonthOrWeek(1)}
                        />
                        <BodyMediumRegular color="#1E293B" text={formatMonth()} />
                    </div>
                    {((myPlan && permissions.includes('CanAddPlan')) || (!myPlan && planStatus !== 1)) && (
                        <div className={`draft ${getStatusClass(planStatus)}`}>
                            <BodySmallMedium text={planStatus === 1 ? "Draft" : planStatus === 2 ? "Pending Approval" : planStatus === 3 ? "Approved" : planStatus === 4 ? "Needs Revision" : "Draft"} color={planStatus === 1 ? "#777777" : planStatus === 2 ? "#D97706" : planStatus === 4 ? "#E10E0E" : planStatus === 3 ? "#1A8245" : "#777777"} />
                        </div>
                    )}
                </div>
                <div className='otherbutton-container'>
                    <div className='myplan-employeeselect-container'>
                        {permissions?.includes('CanViewSubordinatePlan') && (
                            <>
                                {permissions?.includes('CanAddPlan') && (
                                    <button className={`my-plan-button ${myPlan ? 'my-plan-button-active' : ''}`} onClick={handleMyPlan}>
                                        <img src={myPlan ? myplanIconBlue : myplanIcon} />
                                        <BodySmallRegular text='My Plan' color={myPlan ? "#006CFA" : "#637381"} />
                                    </button>
                                )}
                                <div className='employee-select'>
                                    {/* <CustomSelect
                                        value={selectedEmployee || ""}
                                        onChange={handleEmployeeChange}
                                    >
                                        <option value="" disabled hidden>
                                            Employee
                                        </option>
                                        {subordinates ? (
                                            subordinates !== "isLoading" && subordinates.length > 0 ? (
                                                subordinates.map((subordinate) => (
                                                    <option key={subordinate.id} value={subordinate.id}>
                                                        {`${subordinate.firstName} ${subordinate.lastName}-${subordinate.designationShortName}`}
                                                    </option>
                                                    // <option key={subordinate.id} value={subordinate.id} className="flex justify-between">
                                                    //     <span>{`${subordinate.firstName} ${subordinate.lastName}`}</span>
                                                    //     <span>{subordinate.designationShortName}</span>
                                                    // </option>
                                                ))
                                            ) : subordinates === "isLoading" ? (
                                                <option disabled>Loading...</option>
                                            ) : (
                                                <option disabled>No data</option>
                                            )
                                        ) : (
                                            <option disabled>No data</option>
                                        )}
                                    </CustomSelect> */}
                                    <CustomSubordinateSelect
                                        value={selectedEmployee || ""}
                                        onChange={handleEmployeeChange}
                                        options={options}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    {!myPlan && planStatus === 2 && (
                        <>
                            {permissions.includes('CanRejectPlan') && (
                                <button className='reject-button' onClick={handleRejectPlan}>
                                    <img src={crossIconRed} />
                                    <BodyExtraSmallMedium text='Reject' color='#F23030' />
                                </button>
                            )}
                            {permissions.includes('CanApprovePlan') && (
                                <button className='approve-button' onClick={handleApprovePlan}>
                                    <img src={tickIcon} />
                                    <BodyExtraSmallMedium text='Approve' color='#FFFFFF' />
                                </button>
                            )}
                        </>
                    )}
                    {(planStatus === 1 || planStatus === 4) && myPlan && permissions.includes('CanAddPlan') && (
                        <button className="submit-plan" onClick={handleSubmitPlan}>
                            <BodyExtraSmallMedium color="#FFFFFF" text="Submit Plan" />
                        </button>
                    )}
                </div>
            </div>
            <SubmitPlanModal isLoading={updatePlanStattuses === "isLoading"} isVisible={isSubmitPlanModalVisible} onClose={onSubmitPlanModalClose} onSubmitClick={handleSubmitClick} />
            <ApprovePlanModal isLoading={approvePlanStatus === "isLoading"} isVisible={isApprovePlanModalVisible} onClose={onApprovePlanModalClose} onApproveClick={handleApproveClick} />
            <RejectPlanModal isLoading={rejectPlanStatus === "isLoading"} isVisible={isRejectPlanModalVisible} onClose={onRejectPlanModalClose} onRejectClick={handleRejectClick} />
        </>
    );
};

export default NavigationHeader;