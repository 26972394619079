import React from "react";
import "./AccordianView.css";
import BodySmallMedium from "../designSystem/BodySmallMedium";
import downArrowIcon from "../../assets/images/downArrowIcon.svg";
import PlannerAccordian from "./PlannerAccordian";

const PlanAccordianView = ({ planDates, accordianDoctor }) => {
    return (
        <div className="accordian-view-container">

            <div className="accordian-view-header">
                <div className="col-3 header-col">
                    <BodySmallMedium text="Time" color="#1E293B" />
                    <img src={downArrowIcon} />
                </div>
                <div className="col-3 header-col">
                    <BodySmallMedium text={accordianDoctor ? "Doctor" : "City"} color="#1E293B" />
                    <img src={downArrowIcon} />
                </div>
                <div className="col-6 header-col">
                    <BodySmallMedium text="Practice Location" color="#1E293B" />
                    <img src={downArrowIcon} />
                </div>
            </div>
            {planDates && planDates?.length > 0 ? (
                planDates
                    .sort((a, b) => {
                        const dateA = new Date(a.planDate);
                        const dateB = new Date(b.planDate);
                        return dateA - dateB;
                    })
                    .map((plan, index) => (
                        <PlannerAccordian key={index} plan={plan} />
                    ))
            ) : (
                <div className="accordian-nodata">
                    <BodySmallMedium text="No data" color="#1E293B" />
                </div>
            )}
        </div>
    )
};
export default PlanAccordianView;