import React from 'react';
import PropTypes from 'prop-types';
import './BodyStyle.css'; // Import the CSS file

const Heading6Regular = ({ text, color }) => {
  return (
    <p className="heading6-regular" style={{ color: color }}>
      {text}
    </p>
  );
};

Heading6Regular.propTypes = {
  text: PropTypes.string.isRequired, // Text to display
  color: PropTypes.string.isRequired, // Optional color for the text
};

export default Heading6Regular;
