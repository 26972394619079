import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import {
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import customIcon from '../../assets/images/Vector.svg';
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import { getCities } from "../../actions/userActions/cityAction";
import { getPracticeLocations } from "../../actions/userActions/practiceLocationAction";
import { addVisitProfile, updateVisitProfile } from "../../actions/userActions/VisitProfileAction";
import VisitlocationsRow from "./VisitlocationsRow";
import "./AddVisitprofile.css";
import AddButton from "../subComponents/buttons/CustomAddButton";
import CancelButton from "../subComponents/buttons/CustomCancelButton";
import PrimaryButton from "../subComponents/buttons/PrimaryButton";
import Heading6Regular from "../designSystem/Heading6Regular";
import BodySmallMedium from "../designSystem/BodySmallMedium";
import BodySmallRegular from "../designSystem/BodySmallRegular";

const AddVisitProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const allCities = useSelector((state) => state.cities_reducer.cities);
  const addProfileStatus = useSelector((state) => state.visitProfile_reducer.addVisitProfile);
  const updateProfileStatus = useSelector((state) => state.visitProfile_reducer.updateVisitProfile);
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));

  const initialValues = {
    visitProfileId: 0,
    name: "",
    city: "",
    rows: [{ visitLocationId: 0, practiceLocation: "", doctors: [] }],
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {

    if (location.visitProfile) {
      setFormValues({
        ...formValues,
        visitProfileId: location.visitProfile.row.visitProfileId,
        name: location.visitProfile.row.profileName,
        city: location.visitProfile.row.cityId,
        rows: location.visitProfile.row.visitLocations.map((loc) => ({
          visitLocationId: loc.visitLocationId,
          practiceLocation: loc.practiceLocationId,
          doctors: loc.doctorIds,
        })),
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getCities({ filter: { countryId: "1" }, pageNumber: -1, pageSize: 0 }));
  }, [dispatch]);

  useEffect(() => {
    if (formValues.city) {
      dispatch(
        getPracticeLocations({
          filter: { textSearch: "", city: formValues.city },
          pageNumber: -1,
          pageSize: 0,
        })
      );
    }
  }, [dispatch, formValues.city]);

  const handleChange = (name, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleAddRow = () => {
    setFormValues((prevState) => ({
      ...prevState,
      rows: [
        ...prevState.rows,
        { visitLocationId: 0, practiceLocation: "", doctors: [] },
      ],
    }));
  };


  const handleRemoveRow = (rowIndex) => {
    setFormValues((prevState) => ({
      ...prevState,
      rows: prevState.rows.filter((_, index) => index !== rowIndex),
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formValues.name) errors.name = "Name is required";
    if (!formValues.city) errors.city = "City is required";

    const rowErrors = formValues.rows.map((row) => {
      const rowError = {};
      if (!row.practiceLocation) rowError.practiceLocation = "Practice location is required";
      if (!row.doctors || row.doctors.length === 0) rowError.doctors = "At least one doctor must be selected";
      return rowError;
    });

    if (rowErrors.some((rowError) => Object.keys(rowError).length > 0)) {
      errors.rows = rowErrors;
    }

    return errors;
  };

  const handleSubmit = () => {
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const visitProfileData = {

        visitProfileId: formValues.visitProfileId,
        profileName: formValues.name,
        cityId: formValues.city,
        visitLocations: formValues.rows.map((row) => ({
          visitLocationId: row.visitLocationId,
          practiceLocationId: row.practiceLocation,
          doctorIds: row.doctors,
        })),
      };

      if (location.visitProfile) {
        dispatch(updateVisitProfile(visitProfileData));
      } else {
        dispatch(addVisitProfile(visitProfileData));
      }

      history.push("/admin/visitProfiles");
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (<>
    {addProfileStatus && addProfileStatus != "isLoading" && <Redirect to="/admin/visitProfile" />}
    {updateProfileStatus && updateProfileStatus != "isLoading" && <Redirect to="/admin/visitProfile" />}
    {!location.visitProfile && <Redirect to="/admin/AddVisitProfile" />}
    {!permissions.includes("CanAddVisitProfile") && <Redirect to="/admin" />}
    {!permissions.includes("CanUpdateVisitProfile") && <Redirect to="/admin" />}
    <div className="add-visit-profile-container">
    <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Visit Profile" color="#1E293B" />
                </div>
              
            </div>
            <Heading6Regular  text="Visit Profile" color="#1E293B" />
      <div className="add-content-wrapper">
        <div className="row">
          <div className="col-6">
            <label><BodySmallRegular text='Name' color='#1E293B' /></label>
            <TextField
              name="name"
              value={formValues.name}
              onChange={(e) => handleChange("name", e.target.value)}
              fullWidth
              variant="outlined"
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
          </div>
          <div className="col-6">
            <label><BodySmallRegular text='City' color='#1E293B' /></label>
            <Select
              value={formValues.city}
              onChange={(e) => handleChange("city", e.target.value)}
              fullWidth
              error={!!formErrors.city}
              MenuProps={{ PaperProps: { style: { maxHeight: 210 } } }}
              disabled={!!location.visitProfile?.row.cityId} 
            >
              {allCities === "isLoading" && <MenuItem disabled>Loading...</MenuItem>}
              {allCities !== "isLoading" &&
                allCities.length !== 0 &&
                allCities[0].cities
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((city) => (
                    <MenuItem key={city.id} value={city.id}>
                      {city.name}
                    </MenuItem>
                  ))}
            </Select>
            {formErrors.city && <FormHelperText error>{formErrors.city}</FormHelperText>}
          </div>
        </div>

        <hr />

        <div className="add-visit-profile-row-container">
          {formValues.rows.map((row, index) => (
            <VisitlocationsRow
              key={index}
              row={row}
              index={index}
              formValues={formValues}
              formErrors={formErrors}
              setFormValues={setFormValues}
              setFormErrors={setFormErrors}
              handleRemoveRow={handleRemoveRow}
            />
          ))}
          <div className="add-visit-profile-form-actions-left">
            <AddButton
              onClick={handleAddRow}
              text="+ Add Another"
            />

          </div>
        </div>

        <hr />

        <div className="add-visit-profile-form-actions">
          <CancelButton onClick={handleGoBack} text="Cancel" />
          <PrimaryButton
            onClick={handleSubmit}
            isLoading={addProfileStatus === "isLoading" || updateProfileStatus === "isLoading"}
            text={location.visitProfile ? "Update" : "Save"}
            icon={customIcon}
            width="100px"
            height="50px"
          />

        </div>
      </div>
    </div>
  </>

  );
};

export default AddVisitProfile;
